import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { KeyboardType } from '../components/keyboard/keyboard.component';

export interface KeyboardData {
	isShow?: boolean;
	theme?: KeyboardType;
	formFields?: string[];
	formValues?: object;
	isValid?: boolean;
	formErrors?: object;
	fieldFocused?: string;
	resetValues?: boolean;
	input?: boolean;
}
@Injectable()
export class KeyboardService {
	private keyBoardSubject = new BehaviorSubject<any>({
		isShow: false,
		theme: KeyboardType.IOS,
		formFields: ['email', 'repeat_email'],
		formValues: {},
		formErrors: {},
		isValid: false,
		resetValues: false,
		fieldFocused: 'email',
		input: '',
	});
	keyboard$: Observable<any> = this.keyBoardSubject.asObservable();

	public keyboardEvent: EventEmitter<string> = new EventEmitter<string>();

	show() {
		this.keyBoardSubject.next({ ...this.keyBoardSubject.value, isShow: true });
	}

	hide() {
		this.keyBoardSubject.next({ ...this.keyBoardSubject.value, isShow: false });
	}

	setKeyboarData(keyboard: KeyboardData) {
		this.keyBoardSubject.next({
			...this.keyBoardSubject.value,
			...keyboard,
			formValues: Object.assign(this.keyBoardSubject.value.formValues, keyboard.formValues),
			formErrors: keyboard.isValid ? {} : Object.assign(this.keyBoardSubject.value.formErrors, keyboard.formErrors),
		});
	}

	setKeyboarForm(formValues: KeyboardData) {
		this.keyBoardSubject.next({
			...this.keyBoardSubject.value,
			formValues,
		});
	}

	getKeyboarData() {
		return this.keyBoardSubject.value;
	}

	isFormValid() {
		return this.keyBoardSubject.value.isValid;
	}

	resetKeyboard(formFields?: string[]) {
		this.keyBoardSubject.next({
			isShow: false,
			theme: KeyboardType.IOS,
			formFields: formFields ?? ['email', 'repeat_email'],
			formValues: {},
			formErrors: {},
			isValid: false,
		});
	}

	updateInput(value: string) {
		this.keyBoardSubject.next({
			...this.keyBoardSubject.value,
			input: value,
		});
	}

	emitKeyboardEvent(value: string) {
		this.keyboardEvent.emit(value);
	}
}
