import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LanguageService } from 'src/app/core/services/language.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class ContractService {
	protected http: HttpClient;

	private showValidDocuments: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	showValidDocuments$: Observable<boolean> = this.showValidDocuments.asObservable();

	constructor(
		http: HttpClient,
		private readonly languageService: LanguageService,
	) {
		this.http = http;
	}

	setShowValidDocuments(value: boolean) {
		this.showValidDocuments.next(value);
	}
	getShowValidDocuments(): boolean {
		return this.showValidDocuments.value;
	}
	getDocument(): Observable<any> {
		const lang = this.languageService.getLanguage();
		return this.http.get<any>(environment.apiUrl + `/document/get/${lang}`);
	}
	getDocumentByProduct(productId: string): Observable<any> {
		const lang = this.languageService.getLanguage();
		return this.http.get<any>(environment.apiUrl + `/document/contract/${productId}/${lang}`);
	}
	verifyIdentityOrange(order_id: string, scanType: string) {
		return this.http.post<any>(environment.apiUrl + `/orange/identity-verification`, { order_id, scanType });
	}
}
