<ng-container *ngIf="step === 1">
	<ng-container *ngIf="(bannerService.banners$ | async)!.length === 0">
		<div class="carousel" (click)="openDialog()">
			<div class="carousel__item show">
				<img src="../../../assets/styles/resources/1.jpg" height="100%" width="100%" />
			</div>
		</div>
	</ng-container>
	<ng-container *ngIf="(bannerService.banners$ | async)!.length > 0">
		<div class="carousel" (click)="openDialog()">
			<ng-container *ngFor="let banner of bannerService.banners$ | async; let i = index">
				<div [ngClass]="{ show: showImage === i }" class="carousel__item">
					<img [src]="banner.image" />
				</div>
			</ng-container>
		</div>
	</ng-container>
</ng-container>
<ng-container *ngIf="step === 2">
	<div class="main__header">
		<h1>{{ 'START.WHAT_TO_DO' | translate }}</h1>
	</div>
	<div class="card-group">
		<app-card
			routerLink="/buy-card/1"
			icon="icon-sim-2"
			iconSize="icon--lg"
			label="{{ 'START.BUY_PREPAID_CARD' | translate }}"
			(click)="selectOption(PurchaseType.BuyCard)"
		></app-card>
		<app-card
			routerLink="/recharge-balance/step/20"
			icon="icon-top-up-euro"
			iconSize="icon--lg"
			label="{{ 'START.RECHARGE_BALANCE' | translate }}"
			(click)="selectOption(PurchaseType.RechargeBalance)"
		></app-card>
	</div>
	<div [ngClass]="{ alert: true, 'alert--animated': true, 'alert--md': true, show: showItem }">
		<svg width="73" height="72" viewBox="0 0 73 72" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clip-path="url(#clip0_5150_12292)">
				<rect
					x="71.105"
					y="31.7144"
					width="39.8351"
					height="65.7539"
					transform="rotate(94.8825 71.105 31.7144)"
					fill="black"
				/>
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M64.2297 59.0616L65.6118 42.8825C61.483 42.5296 58.4038 38.9519 58.642 34.8406L16.4495 31.2364C16.0829 34.4779 13.7217 37.0363 10.7036 37.7845L9.2525 54.7722C12.1615 56.0486 14.0743 59.0709 13.7889 62.4124C13.7883 62.4197 13.7876 62.4271 13.787 62.4344L55.9739 66.0381L55.9757 66.0165C56.3345 61.8167 60.0299 58.7029 64.2297 59.0616Z"
					fill="white"
				/>
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M27.6396 47.992C27.2103 53.0174 30.9362 57.4394 35.9617 57.8686C40.9871 58.2979 45.409 54.572 45.8383 49.5466C46.2676 44.5211 42.5417 40.0992 37.5163 39.6699C32.4908 39.2406 28.0689 42.9665 27.6396 47.992ZM39.7436 51.7631C39.8538 51.7792 39.9608 51.8119 40.0611 51.8601C40.4655 52.0303 40.7211 52.435 40.6706 52.8165C40.6551 52.9455 40.6095 53.0691 40.5376 53.1772C39.6257 54.7262 38.322 55.3122 36.3156 55.0467C35.0335 54.877 34.0291 54.3697 33.3374 53.4859C32.6959 52.6663 32.3311 51.7002 32.261 50.5429L31.6083 50.4566C31.1302 50.3933 30.8827 49.9797 30.9452 49.5078C31.0086 49.0286 31.3469 48.7 31.8323 48.7643L32.351 48.8329C32.3679 48.6917 32.3998 48.4503 32.4118 48.3593L32.4119 48.3592C32.4295 48.2263 32.4472 48.1288 32.4726 47.9894L32.4728 47.9881L31.9443 47.9181C31.4662 47.8548 31.2187 47.4412 31.2811 46.9694C31.3436 46.4976 31.6901 46.1626 32.1682 46.2258L32.8698 46.3187C33.2689 45.2236 33.9346 44.1715 34.7655 43.5591C35.6568 42.9017 36.7514 42.6552 37.9349 42.8118C39.8731 43.0683 41.0288 44.0186 41.4682 45.7167C41.5157 45.8628 41.5287 46.018 41.5063 46.1699C41.4572 46.5414 41.1954 46.8175 40.7879 46.9278L40.773 46.9315L40.7575 46.9328C40.6426 46.9511 40.4924 46.9675 40.3516 46.9488C39.9785 46.8995 39.7065 46.6459 39.6042 46.2611C39.3215 45.1996 38.823 44.7616 37.6966 44.6125C36.3937 44.4401 35.3631 45.0703 34.7426 46.5666L38.8701 47.1129C39.3556 47.1771 39.5977 47.5773 39.5355 48.047C39.4719 48.5279 39.1245 48.8684 38.6462 48.8051L34.2799 48.2273C34.2778 48.2387 34.2757 48.2498 34.2738 48.2607C34.2515 48.3821 34.2359 48.4671 34.2185 48.5984C34.2071 48.6851 34.1745 48.9308 34.1581 49.0721L37.7878 49.5525C38.2805 49.6177 38.5153 50.0151 38.4509 50.5012C38.3894 50.9659 38.0348 51.3071 37.5638 51.2448L34.127 50.7899C34.2952 52.3907 35.1723 53.0727 36.5527 53.2554C37.7451 53.4132 38.3155 53.1252 38.7923 52.2748L38.794 52.2716L38.7959 52.2684C39.02 51.8914 39.3567 51.7119 39.7436 51.7631Z"
					fill="black"
				/>
				<ellipse
					cx="53.4571"
					cy="50.2843"
					rx="2.43533"
					ry="2.43533"
					transform="rotate(94.8825 53.4571 50.2843)"
					fill="black"
				/>
				<ellipse
					cx="20.0061"
					cy="47.4268"
					rx="2.43533"
					ry="2.43533"
					transform="rotate(94.8825 20.0061 47.4268)"
					fill="black"
				/>
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M63.1063 -0.618748H35.375V19.4625C35.375 20.2233 35.6772 20.953 36.2152 21.491C36.7532 22.029 37.4829 22.3313 38.2438 22.3313H42.0688V28.0688L47.8063 22.3313H65.975V2.25C65.975 1.48916 65.6728 0.759485 65.1348 0.22149C64.5968 -0.316505 63.8671 -0.618748 63.1063 -0.618748ZM57.3688 18.5063H43.9813C43.6448 18.5063 43.3143 18.4176 43.0231 18.249C42.7319 18.0805 42.4904 17.8381 42.3228 17.5464C42.1552 17.2546 42.0676 16.9238 42.0688 16.5874C42.0699 16.251 42.1598 15.9208 42.3294 15.6302L42.3451 15.6032L42.3459 15.602L49.0232 4.1552C49.1915 3.86644 49.4327 3.62687 49.7225 3.46036C50.0123 3.29385 50.3407 3.20623 50.675 3.20623C51.0093 3.20623 51.3377 3.29385 51.6275 3.46036C51.9173 3.62687 52.1585 3.86644 52.3268 4.1552L59.0206 15.6302H59.0204C59.19 15.9208 59.2799 16.2509 59.281 16.5874C59.2822 16.9238 59.1946 17.2546 59.0271 17.5463C58.8595 17.838 58.618 18.0804 58.3268 18.249C58.0357 18.4175 57.7052 18.5063 57.3688 18.5063ZM51.1531 7.03125H50.1969C50.0701 7.03125 49.9485 7.08163 49.8588 7.17129C49.7691 7.26096 49.7188 7.38257 49.7188 7.50938V9.9L50.2925 13.725H51.0575L51.6313 9.9V7.50938C51.6313 7.38257 51.5809 7.26096 51.4912 7.17129C51.4015 7.08163 51.2799 7.03125 51.1531 7.03125ZM51.6313 16.5938H49.7188V14.6813H51.6313V16.5938Z"
					fill="#FF7900"
				/>
			</g>
			<defs>
				<clipPath id="clip0_5150_12292">
					<rect width="72" height="72" fill="white" transform="translate(0.5)" />
				</clipPath>
			</defs>
		</svg>

		<span>{{ 'PAY.NOT_RETURN_CHANGE' | translate }}</span>
	</div>
</ng-container>
