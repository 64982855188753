import { Location } from '@angular/common';
import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { LanguageService } from './core/services/language.service';
import { RechargeBalanceService } from './features/recharge-balance/services/recharge-balance.service';
import { AccessibilityService } from './shared/services/accessibility.service';
import { ErrorService } from './shared/services/error.service';
import { IdleService } from './shared/services/idle.service';
import { KeyboardService } from './shared/services/keyboard.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
	showHeader = false;
	showFooter = true;
	showNav = true;
	showKeyboard = false;

	private isOperator: boolean = false;
	private isMobile: boolean;

	constructor(
		readonly accessibilityService: AccessibilityService,
		private readonly languageService: LanguageService,
		private readonly router: Router,
		private el: ElementRef,
		private renderer: Renderer2,
		readonly keyboardService: KeyboardService,
		readonly errorService: ErrorService,
		private readonly idleService: IdleService,
		readonly location: Location,
		private readonly cookieService: CookieService,
		readonly rechargeBalanceService: RechargeBalanceService,
	) {
		this.isMobile = /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
		this.languageService.autoResolveLanguage();
		this.showLayouts();
	}

	ngOnInit(): void {
		this.accessibilityService.accessibility$.subscribe((value) => {
			value
				? this.renderer.addClass(this.el.nativeElement, 'accesibility-mode')
				: this.renderer.removeClass(this.el.nativeElement, 'accesibility-mode');
		});

		this.rechargeBalanceService.getActiveView().subscribe((activeView) => {
			this.showNav = this.isOperator = activeView;
		});
		this.idleService.init();
		if (this.isMobile) {
			window.removeEventListener('popstate', this.preventBackNavigation);
		}
	}

	preventBackNavigation = (event: PopStateEvent) => {
		// Evitar la navegación hacia atrás
		this.location.forward();
	};

	private showLayouts() {
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				this.showHeader = !event.url.includes('/start/1') || event.url.includes('/pay/7');
				this.showNav =
					event.url.includes('buy-card/details') ||
					(event.url.includes('recharge-balance/step/20') && !!this.isOperator) ||
					event.url.includes('recharge-balance/step/21') ||
					event.url.includes('recharge-balance/step/22') ||
					(event.url.includes('/contract') &&
						!event.url.includes('/contract/step/5') &&
						!event.url.includes('/contract/step/7') &&
						!event.url.includes('/contract/step/8') &&
						!event.url.includes('/contract/step/10')) ||
					event.url.includes('/pay/8') ||
					event.url.includes('/pay/10') ||
					event.url.includes('/pay/11') ||
					event.url.includes('/pay/12') ||
					event.url.includes('/pay/15') ||
					event.url.includes('/pay/18') ||
					event.url.includes('/start/1') ||
					event.url.includes('/buy-card/contract/step/8') ||
					event.url === '/';
				if (event.url.includes('/start/1') || event.url === '/') {
					this.renderer.addClass(this.el.nativeElement, 'welcome');
					this.showFooter = true;
					this.showHeader = false;
				} else this.renderer.removeClass(this.el.nativeElement, 'welcome');
				if (event.url.includes('/start/2')) this.showFooter = true;
				if (event.url.includes('/pay/7') || event.url.includes('/maintenance')) {
					this.showFooter = false;
					this.showHeader = false;
					this.renderer.addClass(this.el.nativeElement, 'end');
				} else this.renderer.removeClass(this.el.nativeElement, 'end');
				if (event.url.includes('/jwt/')) {
					const chunks = event.url.split('/jwt/');
					this.cookieService.set('token', chunks[1], new Date(), '/', undefined, true, 'Strict');
					this.router.navigate(['/start/1']);
				}
			}
		});
		this.errorService.hasError$.subscribe((value) => {
			if (this.location.path().includes('pay/7') && value) {
				this.showFooter = true;
				this.showHeader = true;
				this.renderer.removeClass(this.el.nativeElement, 'end');
			}
		});
	}
}
