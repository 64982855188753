import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class PaymentService {
	protected http: HttpClient;

	constructor(http: HttpClient) {
		this.http = http;
	}

	paymentByCash(order_id: string, type: string): Observable<any> {
		return this.http.post<any>(environment.apiUrl + `/cash/prepare`, { order_id, type });
	}

	cashClose(): Observable<any> {
		return this.http.post<any>(environment.apiUrl + `/cash/close`, {});
	}
}
