import { Component } from '@angular/core';

@Component({
	selector: 'app-help',
	templateUrl: './help.component.html',
	styleUrls: ['./help.component.scss'],
	host: {
		class: 'main',
	},
})
export class HelpComponent {
	help = [
		{ title: 'WHAT_IS_ESIM', visible: false },
		{ title: 'HOW_TO_PURCHASE_MULTIPLE_PREPAID_CARDS', visible: false },
		{ title: 'VALID_DOCUMENTS', visible: false },
		{ title: 'HOW_TO_SCAN_DOCUMENT', visible: false },
		{ title: 'DOCUMENT_NOT_DETECT', visible: false },
		{ title: 'DOCUMENT_NOT_VALID', visible: false },
		{ title: 'AVAILABLE_COMPANIES', visible: false },
		{ title: 'DO_NOT_PAY', visible: false },
		{ title: 'DO_NOT_EFFECTIVE', visible: false },
		{ title: 'DO_NOT_EXPEND_CARD', visible: false },
		{ title: 'DO_NOT_COMPLETE_RECHARGE', visible: false },
		{ title: 'KIOSKO_DOES_NOT_WORK', visible: false },
	];

	constructor() {}

	objectKeys(obj: any): any {
		return Object.keys(obj);
	}

	objectValues(obj: any): any {
		return Object.values(obj);
	}

	changeObjectStatus(index: number) {
		this.help.forEach((help, i) => {
			help.visible = false;
			if (i === index) this.help[index].visible = !this.help[index].visible;
		});
	}
}
