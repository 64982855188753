import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ErrorService {
	private originalRequest: HttpRequest<any> | null = null;
	private hasErrorSubject = new BehaviorSubject<boolean>(false);
	private returnBills = new BehaviorSubject<boolean>(false);
	private ErrorCauseSubject = new BehaviorSubject<string>('');
	private ErrorTicketSubject = new BehaviorSubject<boolean>(false);
	private ErrorPaySubject = new BehaviorSubject<string>('');
	private ErrorQRSubject = new BehaviorSubject<boolean>(false);
	private isRetryingSubject = new BehaviorSubject<boolean>(false);
	hasError$: Observable<boolean> = this.hasErrorSubject.asObservable();
	isRetrying$: Observable<boolean> = this.isRetryingSubject.asObservable();
	errorCause$: Observable<string> = this.ErrorCauseSubject.asObservable();
	returnBills$: Observable<boolean> = this.returnBills.asObservable();

	fullRefund = new BehaviorSubject<boolean>(false);

	constructor() {}

	//SETTERS Y GETTERS ERRORES GENERICOS
	setError() {
		this.hasErrorSubject.next(true);
	}

	setErrorCause(cause: string) {
		this.ErrorCauseSubject.next(cause);
	}

	getErrorCause(): string {
		return this.ErrorCauseSubject.value;
	}

	setReturnBills(cause: boolean) {
		this.returnBills.next(cause);
	}

	getReturnBills(): boolean {
		return this.returnBills.value;
	}

	setFullRefund(cause: boolean) {
		this.fullRefund.next(cause);
	}

	getFullRefund(): boolean {
		return this.fullRefund.value;
	}

	//SETTERS Y GETTERS ERRORES TICKETS DE COMPRA
	setErrorTicketCause(cause: boolean) {
		this.ErrorTicketSubject.next(cause);
	}

	getErrorTicketCause(): boolean {
		return this.ErrorTicketSubject.value;
	}

	//SETTERS Y GETTERS ERRORES PAY TARJETA O EFECTIVO
	setErrorPayCause(cause: string) {
		this.ErrorPaySubject.next(cause);
	}

	getErrorPayCause(): string {
		return this.ErrorPaySubject.value;
	}

	//SETTERS Y GETTERS ERRORES QR ESIM
	setErrorQRCause(cause: boolean) {
		this.ErrorQRSubject.next(cause);
	}

	getErrorQRCause(): boolean {
		return this.ErrorQRSubject.value;
	}

	removeError() {
		this.hasErrorSubject.next(false);
	}

	setOriginalRequest(request: HttpRequest<any> | null) {
		this.originalRequest = request;
	}

	getOriginalRequest(): HttpRequest<any> {
		return this.originalRequest!;
	}

	setRetrying(isRetrying: boolean) {
		this.isRetryingSubject.next(isRetrying);
	}
}
