import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
	transform(value: string): string {
		if (!value) {
			return value;
		}
		const parts = value.split('/');
		return parts.length > 1 ? parts[parts.length - 1] : value;
	}
}
