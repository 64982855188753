<div class="modal show">
	<div class="modal__content">
		<div *ngIf="isLoading" class="loader">
			<svg class="spinner" xmlns="http://www.w3.org/2000/svg" width="240" height="240" viewBox="0 0 240 240" fill="none">
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M120 56C84.6538 56 56 84.6538 56 120C56 155.346 84.6538 184 120 184C155.346 184 184 155.346 184 120C184 110.814 182.07 102.101 178.6 94.2253L193.242 87.7747C197.59 97.6437 200 108.551 200 120C200 164.183 164.183 200 120 200C75.8172 200 40 164.183 40 120C40 75.8172 75.8172 40 120 40V56Z"
					fill="#FF7900"
				/>
			</svg>
		</div>
		<div *ngIf="!isLoading" class="modal__content__body">
			<div class="body__header">
				<h2>{{ fee.info.subtitle }}</h2>
				<span>{{ fee.info.title }} </span>
			</div>
			<div *ngIf="!(accessibilityService.accessibility$ | async)" class="collapse-group">
				<ng-container *ngFor="let detail of fee.details; trackBy: trackByFeeDetailID">
					<div
						class="collapse"
						[ngClass]="{ 'collapse--expanded': detailIdSelected === detail.product_detail_id }"
						(click)="detailIdSelected = detailIdSelected === detail.product_detail_id ? -1 : detail.product_detail_id"
					>
						<div class="collapse__header">
							<div class="collapse__header__title">
								<i class="icon--sm" [ngClass]="'icon-' + detail.icon"></i>
								<div class="title__text">
									<h5>{{ detail.title }}</h5>
									<span>{{ detail.subtitle }}</span>
								</div>
							</div>
							<i class="icon-triangle-down"></i>
						</div>
						<div [innerHTML]="detail.description" class="collapse__body"></div>
					</div>
				</ng-container>
			</div>
			<div *ngIf="accessibilityService.accessibility$ | async" class="collapse-carousel">
				<div class="collapse-group">
					<ng-container *ngFor="let detail of fee.details; trackBy: trackByFeeDetailID; let i = index">
						<div class="collapse" [ngClass]="{ show: indexSelected === i }">
							<div class="collapse__header">
								<div class="collapse__header__title">
									<i class="icon--sm" [ngClass]="'icon-' + detail.icon"></i>
									<div class="title__text">
										<h5>{{ detail.title }}</h5>
										<span>{{ detail.subtitle }}</span>
									</div>
								</div>
							</div>
							<div [innerHTML]="detail.description" class="collapse__body"></div>
						</div>
					</ng-container>
				</div>
				<div class="collapse-carousel__pagination">
					<button class="button-icon" (click)="subtract()">
						<i class="icon-arrow-left"></i>
					</button>
					<span>{{ indexSelected + 1 }}/{{ fee.details.length }}</span>
					<button class="button-icon" (click)="add()">
						<i class="icon-arrow-right"></i>
					</button>
				</div>
			</div>
		</div>
		<div class="modal__content__buttons">
			<button class="button" type="button" (click)="dialogRef.close()">
				<i class="icon-chevron-left"></i>
				{{ 'GENERAL.BACK' | translate }}
			</button>
		</div>
	</div>
</div>
