<ng-container *ngIf="!(errorService.hasError$ | async); else errors">
	<div class="main__header">
		<h1>{{ 'BUY_CARD.COMPLETE_YOUR_SELECTION' | translate }}</h1>
		<span>{{
			(purchaseService.purchase$ | async)?.cardType === CardType.Physical
				? ('BUY_CARD.PHYSICS_SIM' | translate)
				: ('BUY_CARD.ESIM' | translate)
		}}</span>
	</div>
	<div class="main__content" *ngIf="(buyCardService.fees$ | async)?.feeSelected as fee">
		<div class="main__content__section">
			<div class="item-group">
				<app-item
					type="amount"
					tagLabel="{{ fee.title }}"
					tagColor="{{ tagColor }}"
					title="{{ fee.subtitle }}"
					price="{{ fee.price | number: '1.0-0' }}"
					pricePeriod="{{ fee.validity_days }}"
					(valueChange)="valueChange($event)"
				></app-item>
			</div>
		</div>
		<!-- ACTIVATE ONCE ROAMING IS ACTIVE -->
		<!-- <div class="main__content__section">
			<div class="section__title">
				<h3>{{ 'BUY_CARD.VISIT_OTHER_COUNTRIES_QUESTION' | translate }}</h3>
				<span>{{ 'BUY_CARD.GB_AVAILABLE' | translate }}</span>
			</div>
			<div class="tile tile--banner">
				<div class="tile__header">
					<i class="icon-roaming"></i>
					<h4>{{ 'BUY_CARD.WEEKLY_ROAMING' | translate }}</h4>
					<span>{{ 'BUY_CARD.DESTINATIONS_PLUS_COUNTRIES' | translate }}</span>
				</div>
				<div class="tile__actions" *ngIf="!isRoamingActions">
					<button
						*ngIf="isLoading"
						[ngClass]="{ 'button--loader': isLoading }"
						class="button button--important button--sm"
						type="button"
					>
						{{ 'GENERAL.ADD' | translate }}
					</button>
					<button
						*ngIf="!isLoading"
						[ngClass]="{ 'button--loader': isLoading }"
						class="button button--important button--sm"
						type="button"
						(click)="addRoaming()"
					>
						{{ 'GENERAL.ADD' | translate }}
					</button>
					<a (click)="openDialog()">{{ 'GENERAL.SEE_DETAILS' | translate }}</a>
				</div>

				<div class="tile__actions" *ngIf="isRoamingActions">
					<div class="tile__actions__summary">
						<span>{{ feeCount }} ud</span>
						<div class="divider"></div>
						<span>{{ 'GENERAL.PRICE' | translate }} {{ roamingData.info.price | number: '1.0-0' }}€</span>
						<div class="divider"></div>
						<h5>{{ 'GENERAL.TOTAL' | translate }} {{ totalRoamingAmount | number: '1.0-0' }}€</h5>
					</div>
					<button class="button button--sm" type="button" (click)="subtractRoaming()">
						{{ 'GENERAL.DELETE' | translate }}
					</button>
				</div>
			</div>
		</div> -->
	</div>
</ng-container>
<ng-template #errors>
	<app-error></app-error>
</ng-template>
