import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AccessibilityService } from '../../services/accessibility.service';

@Component({
	selector: 'app-exit-modal',
	templateUrl: './exit-modal.component.html',
	styleUrls: ['./exit-modal.component.scss'],
})
export class ExitModalComponent implements OnInit {
	dialogRef: MatDialogRef<ExitModalComponent>;
	constructor(
		dialogRef: MatDialogRef<ExitModalComponent>,
		private el: ElementRef,
		private renderer: Renderer2,
		private readonly accessibilityService: AccessibilityService,
	) {
		this.dialogRef = dialogRef;
	}
	ngOnInit(): void {
		if (this.accessibilityService.getAccessibility())
			this.renderer.addClass(this.el.nativeElement, 'modal-accessibility');
	}
}
