import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class TimeoutService {
	private timeoutRefs: ReturnType<typeof setTimeout>[] = [];
	private cancelPendingRequests$ = new Subject<boolean>();

	cancelPendingRequests() {
		this.cancelPendingRequests$.next(true);
	}
	onCancelPendingRequests() {
		return this.cancelPendingRequests$.asObservable();
	}
	setTimeout(key: ReturnType<typeof setTimeout>): void {
		this.timeoutRefs.push(key);
	}

	clearTimeout(key: ReturnType<typeof setTimeout>): void {
		this.timeoutRefs = this.timeoutRefs.filter((value) => key !== value);
	}

	clearAllTimeouts(): void {
		this.timeoutRefs.forEach((key) => this.clearTimeout(key));
	}
}
