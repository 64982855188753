import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PurchaseService } from './purchase.service';

@Injectable({
	providedIn: 'root',
})
export class ScannerService {
	private scannerSubject = new BehaviorSubject<boolean>(false);
	isScannerActivate$: Observable<boolean> = this.scannerSubject.asObservable();
	private secondPartSubject = new BehaviorSubject<boolean>(false);
	secondPart$: Observable<boolean> = this.secondPartSubject.asObservable();
	protected http: HttpClient;

	constructor(
		http: HttpClient,
		private readonly purchaseService: PurchaseService,
	) {
		this.http = http;
	}

	setScannerStatus(status: boolean) {
		this.scannerSubject.next(status);
	}

	getScannerStatus(): boolean {
		return this.scannerSubject.value;
	}

	activateScanner(scanType: string): Observable<any> {
		const purchase = this.purchaseService.getPurchase();
		return this.http.post<any>(environment.apiUrl + `/scanner/scan`, {
			order_id: purchase.orderId,
			scanType,
		});
	}

	resetScanner(): Observable<any> {
		const purchase = this.purchaseService.getPurchase();
		return this.http.post<any>(environment.apiUrl + `/scanner/restart-process`, {
			order_id: purchase.orderId,
		});
	}

	secondNIFPart(boolean: boolean) {
		this.secondPartSubject.next(boolean);
	}

	getSecondNIFPart(): boolean {
		return this.secondPartSubject.value;
	}
}
