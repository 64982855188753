import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-card',
	templateUrl: './card.component.html',
	styleUrls: ['./card.component.scss'],
})
export class CardComponent {
	@Input()
	description?: string;

	@Input()
	type?: string;

	@Input()
	icon?: string;

	@Input()
	label?: any;

	@Input()
	size?: string;

	@Input()
	iconSize?: string;

	public get iconClasses(): string[] {
		const classes = [
			this.iconSize && this.iconSize !== 'default' ? `${this.iconSize}` : '',
			this.icon && this.icon !== 'default' ? `${this.icon}` : '',
		].filter((n) => n);
		return classes;
	}
}
