<div class="modal show">
	<div class="modal__content">
		<div class="modal__content__body">
			<div class="body__header">
				<h2>{{ 'LANGUAGE.SELECT_YOUR_LANGUAGE' | translate }}</h2>
				<span>{{ 'GENERAL.CHOOSE_LANGUAGE' | translate }}</span>
			</div>
			<div class="button-toggle-group">
				<ng-container *ngFor="let lang of languageOptions; trackBy: trackByCode">
					<button class="button-toggle" (click)="selectLanguage(lang.code)" type="button">
						{{ lang.label }}
					</button>
				</ng-container>
			</div>
		</div>
		<div class="modal__content__buttons">
			<button class="button" type="button" (click)="dialogRef.close()">
				<i class="icon-chevron-left"></i>
				{{ 'GENERAL.BACK' | translate }}
			</button>
		</div>
	</div>
</div>
