import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { InactivityDialogComponent } from '../components/inactivity-dialog/inactivity-dialog.component';
import { AccessibilityService } from './accessibility.service';
import { ErrorService } from './error.service';
import { KeyboardService } from './keyboard.service';
import { PurchaseService } from './purchase.service';
import { StepperService } from './stepper.service';
import { TicketService } from './ticket.service';
import { TimeoutService } from './timeout.service';

@Injectable({
	providedIn: 'root',
})
export class IdleService {
	private timeoutId: any;
	private regularTimeout: number = 30000;
	private hardwareViewTimeout: number = 180000;
	private activationSIMTimeout: number = 90000;
	protected http: HttpClient;
	private hardwareStatusSubject = new BehaviorSubject<boolean>(true);
	hardwareStatus$: Observable<boolean> = this.hardwareStatusSubject.asObservable();

	constructor(
		http: HttpClient,
		private dialog: MatDialog,
		private readonly location: Location,
		private readonly keyboardService: KeyboardService,
		private readonly errorService: ErrorService,
		private readonly accessibilityService: AccessibilityService,
		private readonly timeoutService: TimeoutService,
		private readonly purchaseService: PurchaseService,
		private readonly stepperService: StepperService,
		private readonly router: Router,
		private printTicket: TicketService,
	) {
		this.http = http;
	}

	public resetTimer() {
		let currentUrl = this.router.url;
		this.router.events.subscribe((router: any) => {
			if (router instanceof NavigationEnd) {
				currentUrl = router.url;
				if (
					currentUrl.includes('buy-card/contract/step/8') ||
					currentUrl.includes('pay/1') ||
					currentUrl.includes('pay/8')
				) {
					clearTimeout(this.timeoutId);
					this.timeoutId = setTimeout(() => this.showInactivityDialog('sixty'), this.hardwareViewTimeout);
				} else if (currentUrl.includes('pay/6')) {
					clearTimeout(this.timeoutId);
					this.timeoutId = setTimeout(() => this.showInactivityDialog('ninety'), this.activationSIMTimeout);
				} else {
					clearTimeout(this.timeoutId);
					this.timeoutId = setTimeout(() => this.showInactivityDialog('thirty'), this.regularTimeout);
				}
			}
		});
		const timeout =
			currentUrl.includes('buy-card/contract/step/8') || currentUrl.includes('pay/1') || currentUrl.includes('pay/8')
				? this.hardwareViewTimeout
				: currentUrl.includes('pay/6')
					? this.activationSIMTimeout
					: this.regularTimeout;

		clearTimeout(this.timeoutId);
		this.timeoutId = setTimeout(
			() => this.showInactivityDialog(timeout === 30000 ? 'thirty' : timeout === 90000 ? 'ninety' : 'sixty'),
			timeout,
		);
	}

	private showInactivityDialog(countdownType: string) {
		if (!this.location.path().includes('start/1')) {
			const dialogRef = this.dialog.open(InactivityDialogComponent, {
				width: '300px',
				data: {
					countdown: countdownType === 'thirty' ? 30 : countdownType === 'ninety' ? 15 : 60,
					// countdown: countdownType !== 'thirty' ? 60 : 30,
				},
			});

			dialogRef.afterClosed().subscribe((result) => {
				if (result === 'end') {
					let purchase = this.purchaseService.getPurchase();
					if (
						(purchase.totalAmount !== purchase.remainingAmount && purchase.remainingAmount !== 0) ||
						(!!purchase.isPaymentSuccessful && !purchase.isFinishProcess)
					) {
						this.printTicket
							.printRefundTicket(
								purchase.purchaseType === 0 ? purchase.orderId! : purchase.rechargeId!,
								purchase.purchaseType === 0 ? 'order' : 'recharge',
								'true',
							)
							.subscribe({
								next: () => {
									this.resetHardwareAndSoftware();
								},
								error: () => {
									this.errorService.setError();
								},
							});
					} else {
						this.resetHardwareAndSoftware();
					}
				}
			});
		}
	}

	resetHardwareAndSoftware() {
		this.resetTimer();
		this.timeoutService.cancelPendingRequests();
		this.keyboardService.resetKeyboard();
		this.errorService.removeError();
		this.purchaseService.resetPurchase();
		this.accessibilityService.setAccessibility(false);
		this.timeoutService.clearAllTimeouts();
		this.clearHardware().subscribe();
		this.router.navigate(['/start/1']);
		this.stepperService.setStep(-1);
	}

	public init() {
		this.resetTimer();
		window.addEventListener('mousemove', () => this.resetTimer());
		window.addEventListener('mousedown', () => this.resetTimer());
		window.addEventListener('keypress', () => this.resetTimer());
		window.addEventListener('scroll', () => this.resetTimer());
	}

	clearHardware(): Observable<any> {
		return this.http.post<any>(environment.apiUrl + `/clear-hw-session`, {});
	}

	getHardwareStatus(): Observable<any> {
		return this.http.post<any>(environment.apiUrl + `/hw-status`, {});
	}

	setHardwareStatus(boolean: boolean) {
		this.hardwareStatusSubject.next(boolean);
	}
}
