import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class LoadingBlockService {
	private isLoadingSubject = new BehaviorSubject<boolean>(false);
	isLoading$: Observable<boolean> = this.isLoadingSubject.asObservable();

	setIsLoading(hasAccessibility: boolean) {
		this.isLoadingSubject.next(hasAccessibility);
	}
}
