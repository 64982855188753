import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'app-button',
	templateUrl: './button.component.html',
	styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
	/**
	 * Etiqueta o texto que se mostrará en el botón.
	 */
	@Input()
	label: string | number = '';

	/**
	 * Tipo de botón.
	 */
	@Input()
	type?: 'default' | 'filled' | 'important' = 'default';

	@Input()
	buttonType?: 'button-icon';
	/**
	 * Tamaño del botón.
	 */
	@Input()
	size?: 'default' | 'xs' | 'sm' | 'lg';

	/**
	 * Indica si el botón está deshabilitado.
	 */
	@Input()
	disabled?: boolean;

	/**
	 * Nombre del icono a mostrar en el botón.
	 */
	@Input()
	icon?: string;

	/**
	 * Posición del icono en relación al texto del botón. Puede ser 'right' (derecha) o 'left' (izquierda).
	 */
	@Input()
	iconPosition: 'right' | 'left' = 'right';

	/**
	 * Evento emitido cuando se hace clic en el botón.
	 */
	@Output()
	clickEvent = new EventEmitter<Event>();

	public get classes(): string[] {
		return [
			this.buttonType ? this.buttonType : 'button',
			this.size && this.size !== 'default' ? `button--${this.size}` : '',
			this.type && this.type !== 'default' ? `button--${this.type}` : '',
		];
	}
}
