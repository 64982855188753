<ng-container *ngIf="!(errorService.hasError$ | async); else noErrors">
	<ng-container *ngIf="location.path().includes('buy-card/details/')">
		<div class="cart" *ngIf="!accessibilityService.getAccessibility()">
			<div class="cart__item">
				<h4>{{ 'GENERAL.TOTAL' | translate }}</h4>
				<h4>{{ (purchaseService.purchase$ | async)?.totalAmount | number: '1.0-0' }} €</h4>
			</div>
		</div>
		<button class="button button--filled" type="button" (click)="goToContract()">
			{{ 'GENERAL.CONTINUE' | translate }}
		</button>
	</ng-container>

	<ng-container *ngIf="stepperService.getStep() === 1 && location.path().includes('buy-card/contract/step/1')">
		<button
			class="button button--filled"
			type="button"
			[disabled]="loadingBlockService.isLoading$ | async"
			(click)="continue()"
		>
			{{ 'GENERAL.ACCEPT' | translate }}
		</button>
		<span> {{ 'CONTRACT.ACCEPT_TERM' | translate }}</span>
	</ng-container>

	<ng-container *ngIf="stepperService.getStep() === 2 && !location.path().includes('buy-card/details/')">
		<button class="button button--filled" type="button" (click)="continue()">{{ 'GENERAL.ACCEPT' | translate }}</button>
		<span
			>{{ 'GENERAL.ACCEPT_CONFIRM' | translate }}
			<a (click)="goToPolicy()">{{ 'GENERAL.PRIVACY_EXHIBIT' | translate }}</a></span
		>
	</ng-container>

	<ng-container *ngIf="stepperService.getStep() === 3">
		<ng-container *ngIf="!accessibilityService.getAccessibility()">
			<div class="button-group">
				<button
					*ngIf="
						!(purchaseService.purchase$ | async)?.isAnotherBuy &&
						(purchaseService.purchase$ | async)?.cardType === CardType.Physical
					"
					class="button"
					type="button"
					(click)="changeStep(7)"
				>
					{{ 'GENERAL.SKIP' | translate }}
				</button>
				<button *ngIf="!isSending" class="button button--filled" type="button" (click)="sendEmail()">
					{{ 'GENERAL.SENT' | translate }}
				</button>
				<button *ngIf="isSending" class="button button--filled" type="button">{{ 'GENERAL.SENDING' | translate }}</button>
			</div>
		</ng-container>
		<ng-container *ngIf="accessibilityService.getAccessibility()">
			<div class="button-group">
				<ng-container *ngIf="accessibilityService.isFirstStepFieldActivated()">
					<button
						*ngIf="(purchaseService.purchase$ | async)?.cardType === CardType.Physical"
						class="button"
						type="button"
						(click)="changeStep(7)"
					>
						{{ 'GENERAL.SKIP' | translate }}
					</button>
					<button class="button button--filled" (click)="accessibilityService.changeFirstStep()" type="button">
						{{ 'GENERAL.CONTINUE' | translate }}
					</button>
				</ng-container>
				<ng-container *ngIf="!accessibilityService.isFirstStepFieldActivated()">
					<button class="button" type="button" (click)="accessibilityService.changeFirstStep()">
						{{ 'GENERAL.GET_BACK' | translate }}
					</button>
					<button *ngIf="!isSending" class="button button--filled" type="button" (click)="sendEmail()">
						{{ 'GENERAL.SENT' | translate }}
					</button>
					<button *ngIf="isSending" class="button button--filled" type="button">{{ 'GENERAL.SENDING' | translate }}</button>
				</ng-container>
			</div>
		</ng-container>
	</ng-container>

	<ng-container *ngIf="stepperService.getStep() === 9">
		<div class="cart cart--xl" *ngIf="!accessibilityService.getAccessibility()">
			<div class="cart__summary">
				<div class="cart__summary__item">
					<div class="item__product">{{ 'PAY.SUBTOTAL' | translate }}</div>
					<div class="item__price">0€</div>
				</div>
				<div class="cart__summary__total">
					<div class="total__text">
						<h3>{{ 'PAY.TOTAL' | translate }}</h3>
						<span>{{ 'PAY.TAXES_INCLUDED' | translate }}</span>
					</div>
					<h3 class="total__price">
						{{ (purchaseService.purchase$ | async)?.totalAmount | currency: 'EUR' : 'symbol' : '1.0-0' }}
					</h3>
				</div>
			</div>
			<div class="cart__actions">
				<button class="button--filled button" type="button" (click)="pay()">
					{{
						(purchaseService.purchase$ | async)?.isPaymentSuccessful
							? ('GENERAL.CONTINUE' | translate)
							: ('PAY.PAY' | translate)
					}}
				</button>
			</div>
		</div>
		<ng-container *ngIf="accessibilityService.getAccessibility()">
			<div class="cart">
				<div class="cart__item">
					<h4>{{ 'GENERAL.TOTAL' | translate }}</h4>
					<h4>{{ (purchaseService.purchase$ | async)?.totalAmount | currency: 'EUR' : 'symbol' : '1.0-0' }}</h4>
				</div>
			</div>
			<button class="button button--filled" type="button" (click)="pay()">
				{{
					(purchaseService.purchase$ | async)?.isPaymentSuccessful
						? ('GENERAL.CONTINUE' | translate)
						: ('PAY.PAY' | translate)
				}}
			</button>
		</ng-container>
	</ng-container>

	<ng-container
		*ngIf="stepperService.getStep() === 8 && (purchaseService.purchase$ | async)?.paymentMethod === PaymentMethod.CASH"
	>
		<div class="cart-operation">
			<div class="cart-operation__values">
				<div class="cart-operation__values__total">
					{{ 'PAY.TOTAL' | translate }} <b>{{ purchaseService.getPurchase().totalAmount }}€</b>
				</div>
				<div class="divider"></div>
				<div class="cart-operation__values__miss">
					{{ 'PAY.MISSING' | translate }} <b>{{ (purchaseService.purchase$ | async)?.remainingAmount }}€</b>
				</div>
			</div>
		</div>
	</ng-container>
	<ng-container *ngIf="stepperService.getStep() === 8 && !(purchaseService.purchase$ | async)?.paymentMethod">
		<button
			class="button button--filled"
			type="button"
			[disabled]="(scannerService.isScannerActivate$ | async) || countdown !== 0"
			(click)="activateScanner()"
		>
			{{ 'GENERAL.SCAN_ACTION' | translate }}
			<span *ngIf="countdown !== 0">({{ countdown }}s)</span>
		</button>
	</ng-container>

	<ng-container *ngIf="stepperService.getStep() === 10 && location.path().includes('pay/10')">
		<button
			class="button button--filled"
			type="button"
			[disabled]="loadingBlockService.isLoading$ | async"
			(click)="continue()"
		>
			{{ 'GENERAL.CONTINUE' | translate }}
		</button>
	</ng-container>

	<ng-container *ngIf="stepperService.getStep() === 11">
		<div *ngIf="(purchaseService.purchase$ | async)?.email" class="button-group">
			<button [disabled]="!(idleService.hardwareStatus$ | async)" class="button" (click)="changePayStep(13)" type="button">
				<i class="icon-printer"></i>{{ 'GENERAL.PRINT' | translate }}
			</button>

			<button class="button button--filled" type="button" (click)="changePayStep(4)">
				{{ 'GENERAL.CONTINUE' | translate }}
			</button>
		</div>
	</ng-container>
	<ng-container *ngIf="stepperService.getStep() === 12">
		<ng-container *ngIf="!accessibilityService.getAccessibility()">
			<div class="button-group">
				<button *ngIf="!isSending" class="button button--filled" (click)="sendEmaileSim()" type="button">
					{{ 'GENERAL.SENT' | translate }}
				</button>
				<button *ngIf="isSending" class="button button--filled" type="button">{{ 'GENERAL.SENDING' | translate }}</button>
			</div>
		</ng-container>
		<ng-container *ngIf="accessibilityService.getAccessibility()">
			<div class="button-group">
				<ng-container *ngIf="accessibilityService.isFirstStepFieldActivated()">
					<button class="button button--filled" (click)="accessibilityService.changeFirstStep()" type="button">
						{{ 'GENERAL.CONTINUE' | translate }}
					</button>
				</ng-container>
				<ng-container *ngIf="!accessibilityService.isFirstStepFieldActivated()">
					<button class="button" type="button" (click)="accessibilityService.changeFirstStep()">
						{{ 'GENERAL.GET_BACK' | translate }}
					</button>
					<button *ngIf="!isSending" class="button button--filled" type="button" (click)="sendEmaileSim()">
						{{ 'GENERAL.SENT' | translate }}
					</button>
					<button *ngIf="isSending" class="button button--filled" type="button">{{ 'GENERAL.SENDING' | translate }}</button>
				</ng-container>
			</div>
		</ng-container>
	</ng-container>

	<ng-container *ngIf="stepperService.getStep() === 15">
		<ng-container *ngIf="!accessibilityService.getAccessibility()">
			<div class="button-group">
				<button *ngIf="!isSending" class="button button--filled" (click)="sendEmailLasStep()" type="button">
					{{ 'GENERAL.SENT' | translate }}
				</button>
				<button *ngIf="isSending" class="button button--filled" type="button">{{ 'GENERAL.SENDING' | translate }}</button>
			</div>
		</ng-container>
		<ng-container *ngIf="accessibilityService.getAccessibility()">
			<div class="button-group">
				<ng-container *ngIf="accessibilityService.isFirstStepFieldActivated()">
					<button class="button button--filled" (click)="accessibilityService.changeFirstStep()" type="button">
						{{ 'GENERAL.CONTINUE' | translate }}
					</button>
				</ng-container>
				<ng-container *ngIf="!accessibilityService.isFirstStepFieldActivated()">
					<button class="button" type="button" (click)="accessibilityService.changeFirstStep()">
						{{ 'GENERAL.GET_BACK' | translate }}
					</button>
					<button *ngIf="!isSending" class="button button--filled" type="button" (click)="sendEmailLasStep()">
						{{ 'GENERAL.SENT' | translate }}
					</button>
					<button *ngIf="isSending" class="button button--filled" type="button">{{ 'GENERAL.SENDING' | translate }}</button>
				</ng-container>
			</div>
		</ng-container>
	</ng-container>

	<ng-container *ngIf="stepperService.getStep() === 18">
		<div class="button-group">
			<button *ngIf="!isSending" class="button button--filled" (click)="sendEmailLasStep()" type="button">
				{{ 'GENERAL.SENT' | translate }}
			</button>
			<button *ngIf="isSending" class="button button--filled" type="button">{{ 'GENERAL.SENDING' | translate }}</button>
		</div>
	</ng-container>

	<ng-container *ngIf="stepperService.getStep() === 20 || stepperService.getStep() === 21">
		<div class="button-group">
			<button
				*ngIf="!accessibilityService.getAccessibility() && stepperService.getStep() === 20"
				class="button button--filled"
				type="button"
				(click)="continue('recharge')"
			>
				{{ 'GENERAL.CONTINUE' | translate }}
			</button>
			<button
				*ngIf="
					accessibilityService.isFirstStepFieldActivated() &&
					accessibilityService.getAccessibility() &&
					stepperService.getStep() === 20
				"
				class="button button--filled"
				type="button"
				(click)="accessibilityService.changeFirstStep()"
			>
				{{ 'GENERAL.CONTINUE' | translate }}
			</button>
			<button
				*ngIf="
					!accessibilityService.isFirstStepFieldActivated() &&
					accessibilityService.getAccessibility() &&
					stepperService.getStep() === 20
				"
				class="button"
				type="button"
				(click)="accessibilityService.changeFirstStep()"
			>
				{{ 'GENERAL.GET_BACK' | translate }}
			</button>
			<button
				*ngIf="
					!accessibilityService.isFirstStepFieldActivated() &&
					accessibilityService.getAccessibility() &&
					stepperService.getStep() === 20
				"
				class="button button--filled"
				type="button"
				(click)="continue('recharge')"
			>
				{{ 'GENERAL.CONTINUE' | translate }}
			</button>
			<button
				*ngIf="stepperService.getStep() === 21"
				class="button button--filled"
				type="button"
				(click)="continue('recharge')"
			>
				{{ 'GENERAL.CONTINUE' | translate }}
			</button>
		</div>
	</ng-container>

	<ng-container *ngIf="stepperService.getStep() === 22">
		<div class="cart cart--xl" *ngIf="!accessibilityService.getAccessibility()">
			<div class="cart__summary">
				<div class="cart__summary__item">
					<div class="item__product">{{ 'RECHARGE.SUBTOTAL' | translate }}</div>
					<div class="item__price">
						{{ (purchaseService.purchase$ | async)?.totalAmount | currency: 'EUR' : 'symbol' : '1.0-0' }}
					</div>
				</div>
				<div class="cart__summary__total">
					<div class="total__text">
						<h3>{{ 'GENERAL.TOTAL' | translate }}</h3>
						<span>{{ 'RECHARGE.VAT_INCLUDED' | translate }}</span>
					</div>
					<h3 class="total__price">
						{{ (purchaseService.purchase$ | async)?.totalAmount | currency: 'EUR' : 'symbol' : '1.0-0' }}
					</h3>
				</div>
			</div>
			<div class="cart__actions">
				<button class="button--filled button" type="button" (click)="pay()">
					{{
						(purchaseService.purchase$ | async)?.isPaymentSuccessful
							? ('GENERAL.CONTINUE' | translate)
							: ('RECHARGE.PAY' | translate)
					}}
				</button>
			</div>
		</div>
		<ng-container *ngIf="accessibilityService.getAccessibility()">
			<div class="cart">
				<div class="cart__item">
					<h4>{{ 'GENERAL.TOTAL' | translate }}</h4>
					<h4>{{ (purchaseService.purchase$ | async)?.totalAmount | currency: 'EUR' : 'symbol' : '1.0-0' }}</h4>
				</div>
			</div>
			<button class="button button--filled" type="button" (click)="pay()">{{ 'PAY.PAY' | translate }}</button>
		</ng-container>
	</ng-container>

	<ng-container *ngIf="location.path().includes('start/1')">
		<div *ngIf="!showLanguage; else showLanguages" class="main__content">
			<div class="loader">
				<svg class="spinner" xmlns="http://www.w3.org/2000/svg" width="240" height="240" viewBox="0 0 240 240" fill="none">
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M120 56C84.6538 56 56 84.6538 56 120C56 155.346 84.6538 184 120 184C155.346 184 184 155.346 184 120C184 110.814 182.07 102.101 178.6 94.2253L193.242 87.7747C197.59 97.6437 200 108.551 200 120C200 164.183 164.183 200 120 200C75.8172 200 40 164.183 40 120C40 75.8172 75.8172 40 120 40V56Z"
						fill="#FF7900"
					/>
				</svg>
			</div>
		</div>
		<ng-template #showLanguages>
			<h3>{{ 'GENERAL.SELECT_LANGUAGE' | translate }}</h3>
			<div class="button-toggle-group">
				<ng-container *ngFor="let lang of languageOptions; trackBy: trackByCode">
					<button class="button-toggle" (click)="selectLanguage(lang.code)" type="button">
						{{ lang.label }}
					</button>
				</ng-container>
			</div>
		</ng-template>
	</ng-container>
</ng-container>
<ng-template #noErrors>
	<ng-container
		*ngIf="stepperService.getStep() === 13 && location.path().includes('pay/13') && errorService.getErrorQRCause()"
	>
		<button
			*ngIf="!isSending && stepperService.getStep() === 13 && location.path().includes('pay/13')"
			class="button button--filled"
			(click)="sendEmailTicketError(true)"
			type="button"
		>
			<i class="icon-mail"></i> {{ 'GENERAL.SENT' | translate }}
		</button>
		<button
			*ngIf="isSending && stepperService.getStep() === 13 && location.path().includes('pay/13')"
			class="button button--filled"
			type="button"
		>
			{{ 'GENERAL.SENDING' | translate }}
		</button>
	</ng-container>

	<ng-container
		*ngIf="
			(stepperService.getStep() === 2 && location.path().includes('pay/2')) ||
			(stepperService.getStep() === 8 && location.path().includes('pay/8'))
		"
	>
		<ng-container *ngIf="!accessibilityService.getAccessibility()">
			<div class="button-group">
				<!-- CARD -->
				<button
					*ngIf="errorService.getErrorPayCause() === 'CARD'"
					class="button"
					disabled
					(click)="retryPay('CARD')"
					type="button"
				>
					<i class="icon-card"></i> {{ 'PAY.CARD' | translate }}
				</button>
				<button
					*ngIf="errorService.getErrorPayCause() === 'CARD'"
					class="button button--filled"
					(click)="retryPay('CASH')"
					type="button"
				>
					<i class="icon-euro"></i> {{ 'PAY.CASH' | translate }}
				</button>
				<!-- CASH -->
				<button *ngIf="errorService.getErrorPayCause() === 'CASH'" class="button" (click)="retryPay('CASH')" type="button">
					<i class="icon-euro"></i> {{ 'PAY.CASH' | translate }}
				</button>
				<button
					*ngIf="errorService.getErrorPayCause() === 'CASH'"
					class="button button--filled"
					disabled
					(click)="retryPay('CARD')"
					type="button"
				>
					<i class="icon-card"></i> {{ 'PAY.CARD' | translate }}
				</button>
			</div>
		</ng-container>
		<ng-container *ngIf="accessibilityService.getAccessibility()">
			<div class="button-group">
				<ng-container *ngIf="accessibilityService.isFirstStepFieldActivated()">
					<!-- CARD -->
					<button
						*ngIf="errorService.getErrorPayCause() === 'CARD'"
						class="button"
						disabled
						(click)="retryPay('CARD')"
						type="button"
					>
						<i class="icon-card"></i> {{ 'PAY.CARD' | translate }}
					</button>
					<button
						*ngIf="errorService.getErrorPayCause() === 'CARD'"
						class="button button--filled"
						(click)="retryPay('CASH')"
						type="button"
					>
						<i class="icon-euro"></i> {{ 'PAY.CASH' | translate }}
					</button>
					<!-- CASH -->
					<button *ngIf="errorService.getErrorPayCause() === 'CASH'" class="button" (click)="retryPay('CASH')" type="button">
						<i class="icon-euro"></i> {{ 'PAY.CASH' | translate }}
					</button>
					<button
						*ngIf="errorService.getErrorPayCause() === 'CASH'"
						class="button button--filled"
						disabled
						(click)="retryPay('CARD')"
						type="button"
					>
						<i class="icon-card"></i> {{ 'PAY.CARD' | translate }}
					</button>
				</ng-container>
				<ng-container *ngIf="!accessibilityService.isFirstStepFieldActivated()">
					<!-- CARD -->
					<button
						*ngIf="errorService.getErrorPayCause() === 'CARD'"
						class="button"
						disabled
						(click)="retryPay('CARD')"
						type="button"
					>
						<i class="icon-card"></i> {{ 'PAY.CARD' | translate }}
					</button>
					<button
						*ngIf="errorService.getErrorPayCause() === 'CARD'"
						class="button button--filled"
						(click)="retryPay('CASH')"
						type="button"
					>
						<i class="icon-euro"></i> {{ 'PAY.CASH' | translate }}
					</button>
					<!-- CASH -->
					<button *ngIf="errorService.getErrorPayCause() === 'CASH'" class="button" (click)="retryPay('CASH')" type="button">
						<i class="icon-euro"></i> {{ 'PAY.CASH' | translate }}
					</button>
					<button
						*ngIf="errorService.getErrorPayCause() === 'CASH'"
						class="button button--filled"
						disabled
						(click)="retryPay('CARD')"
						type="button"
					>
						<i class="icon-card"></i> {{ 'PAY.CARD' | translate }}
					</button>
				</ng-container>
			</div>
		</ng-container>
	</ng-container>
	<button
		*ngIf="!isSending && stepperService.getStep() === 17"
		class="button button--filled"
		(click)="sendEmailTicketError()"
		type="button"
	>
		<i class="icon-mail"></i> {{ 'GENERAL.SENT' | translate }}
	</button>
	<!-- FINISH OPERATION ERROR -->
	<button
		*ngIf="
			errorService.getErrorPayCause() === 'FINISH_PROCESS' &&
			((stepperService.getStep() === 2 && location.path().includes('pay/2')) ||
				(stepperService.getStep() === 6 && location.path().includes('pay/6')) ||
				(stepperService.getStep() === 8 && location.path().includes('pay/8')))
		"
		class="button button--filled"
		(click)="finishProcess()"
		type="button"
	>
		{{ 'GENERAL.FINISH_PROCESS' | translate }}
	</button>
	<button *ngIf="isSending && stepperService.getStep() === 17" class="button button--filled" type="button">
		{{ 'GENERAL.SENDING' | translate }}
	</button>
	<button
		*ngIf="
			!(errorService.isRetrying$ | async) &&
			stepperService.getStep() !== 17 &&
			stepperService.getStep() !== 2 &&
			!location.path().includes('pay/13') &&
			!location.path().includes('pay/8') &&
			errorService.getErrorPayCause() === ''
		"
		class="button button--filled"
		(click)="retry()"
		type="button"
	>
		{{ 'GENERAL.RETRY' | translate }}
	</button>
	<button
		*ngIf="(errorService.isRetrying$ | async) && stepperService.getStep() !== 17"
		class="button button--filled"
		type="button"
	>
		{{ 'GENERAL.RETRYING' | translate }}
	</button>
</ng-template>
