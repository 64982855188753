<ng-container *ngIf="!(errorService.hasError$ | async); else errors">
	<ng-container *ngIf="stepperService.getStep() === 20">
		<ng-container *ngIf="!accessibilityService.getAccessibility()">
			<ng-container *ngIf="!changeOperator; else changeOperatorTemplate">
				<div class="main__header">
					<h1>{{ 'RECHARGE.QUESTION_NUMBER_RECHARGE' | translate }}</h1>
					<span>
						<span>{{ operatorName | titlecase }}</span>
						<a (click)="changeToOperatorPage()">{{ 'RECHARGE.CHANGE_OPERATOR' | translate }}</a>
					</span>
				</div>
				<div class="main__content">
					<div class="grid grid-i2">
						<div class="form-group" [formGroup]="form">
							<div class="form-group__text">
								<label for=""> {{ 'RECHARGE.TELEPHONE_NUMBER' | translate }} </label>
							</div>
							<div class="form-group__input">
								<input
									class="input"
									type="number"
									name="phone"
									[(ngModel)]="phone"
									formControlName="phone"
									(focus)="onFocus('phone')"
									[placeholder]="'RECHARGE.PUT_TELEPHONE_NUMBER' | translate"
									autocomplete="off"
									[value]="phone"
									(click)="setActiveInput('phone')"
								/>
								<button class="button-icon">
									<i
										class="icon-close"
										*ngIf="(keyboardService.keyboard$ | async).formValues.phone"
										(click)="deleteField('phone')"
									></i>
								</button>
							</div>
						</div>
						<div class="form-group" [formGroup]="form">
							<div class="form-group__text">
								<label for=""> {{ 'RECHARGE.CONFIRM_TELEPHONE_NUMBER' | translate }} </label>
							</div>
							<div class="form-group__input">
								<input
									formControlName="phoneRepeat"
									name="phoneRepeat"
									[(ngModel)]="phoneRepeat"
									(focus)="onFocus('phoneRepeat')"
									type="number"
									class="input"
									[placeholder]="'RECHARGE.PUT_TELEPHONE_NUMBER' | translate"
									autocomplete="off"
									[value]="phoneRepeat"
									(click)="setActiveInput('phoneRepeat')"
									[ngClass]="{
										'input--error':
											(keyboardService.keyboard$ | async).formValues.phoneRepeat &&
											((keyboardService.keyboard$ | async).formValues.phoneRepeat.length ===
												(keyboardService.keyboard$ | async).formValues.phone.length ||
												(keyboardService.keyboard$ | async).formValues.phoneRepeat.length >
													(keyboardService.keyboard$ | async).formValues.phone.length) &&
											!phoneMatch,
									}"
								/>
								<button class="button-icon">
									<i
										class="icon-close"
										*ngIf="(keyboardService.keyboard$ | async).formValues.phoneRepeat"
										(click)="deleteField('phoneRepeat')"
									></i>
								</button>
							</div>
							<div
								*ngIf="
									(keyboardService.keyboard$ | async).formValues.phoneRepeat &&
									((keyboardService.keyboard$ | async).formValues.phoneRepeat.length ===
										(keyboardService.keyboard$ | async).formValues.phone.length ||
										(keyboardService.keyboard$ | async).formValues.phoneRepeat.length >
											(keyboardService.keyboard$ | async).formValues.phone.length) &&
									!phoneMatch
								"
								class="form-group__error-message"
							>
								<i class="icon-info"></i>
								{{ 'RECHARGE.TELEPHONE_DOESNOT_MATCH' | translate }}
							</div>
						</div>
					</div>
					<div *ngIf="notProperty" class="alert">
						<i class="icon-info"></i>
						<span>{{ 'RECHARGE.TELEPHONE_NUMBER_DOESNOT_ORANGE_PROPERTY' | translate }}</span>
					</div>
				</div>
			</ng-container>
		</ng-container>
		<ng-container *ngIf="accessibilityService.getAccessibility()">
			<ng-container *ngIf="!changeOperator; else changeOperatorTemplate">
				<div class="main__header">
					<h1>{{ 'RECHARGE.QUESTION_NUMBER_RECHARGE' | translate }}</h1>
					<span>
						<span>{{ operatorName | titlecase }}</span>
						<a *ngIf="false" (click)="changeOperator = true">{{ 'RECHARGE.CHANGE_OPERATOR' | translate }}</a>
					</span>
				</div>
				<div class="main__content">
					<div class="grid grid-i2">
						<div *ngIf="accessibilityService.isFirstStepFieldActivated()" class="form-group" [formGroup]="form">
							<div class="form-group__text">
								<label for=""> {{ 'RECHARGE.TELEPHONE_NUMBER' | translate }} </label>
							</div>
							<div class="form-group__input">
								<input
									class="input"
									type="number"
									name="phone"
									formControlName="phone"
									(focus)="onFocus('phone')"
									[placeholder]="'RECHARGE.PUT_TELEPHONE_NUMBER' | translate"
									autocomplete="off"
								/>
								<button class="button-icon">
									<i
										class="icon-close"
										*ngIf="(keyboardService.keyboard$ | async).formValues.phone"
										(click)="deleteField('phone')"
									></i>
								</button>
							</div>
						</div>
						<div *ngIf="!accessibilityService.isFirstStepFieldActivated()" class="form-group" [formGroup]="form">
							<div class="form-group__text">
								<label for=""> {{ 'RECHARGE.CONFIRM_TELEPHONE_NUMBER' | translate }} </label>
							</div>
							<div class="form-group__input">
								<input
									formControlName="phoneRepeat"
									name="phoneRepeat"
									(focus)="onFocus('phoneRepeat')"
									type="number"
									class="input"
									[ngClass]="{
										'input--error':
											(keyboardService.keyboard$ | async).formValues.phoneRepeat &&
											((keyboardService.keyboard$ | async).formValues.phoneRepeat.length ===
												(keyboardService.keyboard$ | async).formValues.phone.length ||
												(keyboardService.keyboard$ | async).formValues.phoneRepeat.length >
													(keyboardService.keyboard$ | async).formValues.phone.length) &&
											!phoneMatch,
									}"
									[placeholder]="'RECHARGE.PUT_TELEPHONE_NUMBER' | translate"
									autocomplete="off"
								/>
								<button class="button-icon">
									<i
										class="icon-close"
										*ngIf="(keyboardService.keyboard$ | async).formValues.phoneRepeat"
										(click)="deleteField('phoneRepeat')"
									></i>
								</button>
							</div>
							<div
								*ngIf="
									(keyboardService.keyboard$ | async).formValues.phoneRepeat &&
									((keyboardService.keyboard$ | async).formValues.phoneRepeat.length ===
										(keyboardService.keyboard$ | async).formValues.phone.length ||
										(keyboardService.keyboard$ | async).formValues.phoneRepeat.length >
											(keyboardService.keyboard$ | async).formValues.phone.length) &&
									!phoneMatch
								"
								class="form-group__error-message"
							>
								<i class="icon-info"></i>
								{{ 'RECHARGE.TELEPHONE_DOESNOT_MATCH' | translate }}
							</div>
						</div>
					</div>
					<div *ngIf="notProperty" class="alert">
						<i class="icon-info"></i>
						<span>{{ 'RECHARGE.TELEPHONE_NUMBER_DOESNOT_ORANGE_PROPERTY' | translate }}</span>
					</div>
				</div>
			</ng-container>
		</ng-container>
	</ng-container>
	<ng-container *ngIf="stepperService.getStep() === 21">
		<div class="main__header">
			<h1>{{ 'RECHARGE.IMPORT_TO_RECHARGE' | translate }}</h1>
			<span>{{ (purchaseService.purchase$ | async)?.msisdn! | truncatePhoneNumber }}</span>
		</div>
		<div class="main__content">
			<div class="grid grid-d3">
				<ng-container *ngFor="let item of cuantities">
					<button
						class="button-toggle"
						[ngClass]="{ 'button-toggle--selected': cuantity === item }"
						type="button"
						(click)="setCuantity(item)"
					>
						{{ item }}€
					</button>
				</ng-container>
			</div>
		</div>
	</ng-container>
	<ng-container *ngIf="stepperService.getStep() === 22">
		<ng-container *ngIf="!editOperator; else changeOperatorTemplate">
			<div class="main__header">
				<h1><i class="icon-top-up-euro icon--md"></i> {{ 'RECHARGE.YOUR_ORDER' | translate }}</h1>
			</div>
			<div class="main__content">
				<div class="list">
					<div class="list__item">
						<div class="list__item__product">{{ 'RECHARGE.YOUR_OPERATOR' | translate }}</div>
						<div class="list__item__details">
							<div class="details__name">
								<div class="details__name__image">
									<img [src]="imgSrcPath" width="80px" height="80" />
								</div>
							</div>
							<div
								*ngIf="!(purchaseService.purchase$ | async)?.isPaymentSuccessful"
								class="details__info"
								(click)="goToInitialStep()"
							>
								<button class="button button--xs">
									{{ 'GENERAL.EDIT' | translate }}
								</button>
							</div>
						</div>
					</div>
					<div class="list__item">
						<div class="list__item__product">{{ 'RECHARGE.TELEPHONE_NUMBER' | translate }}</div>
						<div class="list__item__details">
							<div class="details__name">
								<h3>{{ (purchaseService.purchase$ | async)?.msisdn! | truncatePhoneNumber }}</h3>
							</div>
							<div class="details__info" routerLink="/recharge-balance/step/20">
								<button *ngIf="!(purchaseService.purchase$ | async)?.isPaymentSuccessful" class="button button--xs">
									{{ 'GENERAL.EDIT' | translate }}
								</button>
							</div>
						</div>
					</div>
					<div class="list__item">
						<div class="list__item__product">{{ 'RECHARGE.TOP_UP' | translate }}</div>
						<div class="list__item__details">
							<div class="details__name">
								<h3>{{ (purchaseService.purchase$ | async)?.totalAmount | currency: 'EUR' : 'symbol' : '1.0-0' }}</h3>
							</div>
							<div
								*ngIf="!(purchaseService.purchase$ | async)?.isPaymentSuccessful"
								class="details__info"
								routerLink="/recharge-balance/step/21"
							>
								<button class="button button--xs">{{ 'GENERAL.EDIT' | translate }}</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</ng-container>
	</ng-container>
	<ng-container *ngIf="stepperService.getStep() === 23">
		<div class="main__header">
			<h1>{{ 'PAY.HOW_TO_PAY' | translate }}</h1>
		</div>
		<div class="card-group">
			<div class="card" type="button">
				<i class="icon-card icon--lg"></i>
				<div class="card__text">
					<h3>{{ 'PAY.CARD' | translate }}</h3>
				</div>
			</div>
			<div class="card" type="button">
				<i class="icon-euro icon--lg"></i>
				<div class="card__text">
					<h3>{{ 'PAY.CASH' | translate }}</h3>
					<div class="alert alert--sm">
						<i class="icon-info"></i>
						{{ 'PAY.NOT_RETURN_CHANGE' | translate }}
					</div>
				</div>
			</div>
		</div>
	</ng-container>
	<ng-template #changeOperatorTemplate>
		<div class="main__header">
			<h1>{{ 'RECHARGE.SELECT_OPERATOR' | translate }}</h1>
		</div>
		<div class="main__content">
			<div class="grid grid-d4">
				<div
					class="card card--sm"
					[ngClass]="{ 'card--selected': operatorSelected === 'orange' }"
					type="button"
					(click)="applyOperator('orange')"
				>
					<img src="../../../assets/styles/resources/orange.png" width="120px" height="80px" alt="" />
				</div>
				<div
					class="card card--sm"
					[ngClass]="{ 'card--selected': operatorSelected === 'simyo' }"
					type="button"
					(click)="applyOperator('simyo')"
				>
					<img src="../../../assets/styles/resources/simyo.svg" alt="" />
				</div>
				<div
					class="card card--sm"
					[ngClass]="{ 'card--selected': operatorSelected === 'masmovil' }"
					type="button"
					(click)="applyOperator('masmovil')"
				>
					<img src="../../../assets/styles/resources/masmovil.svg" alt="" />
				</div>

				<div
					class="card card--sm"
					[ngClass]="{ 'card--selected': operatorSelected === 'yoigo' }"
					type="button"
					(click)="applyOperator('yoigo')"
				>
					<img src="../../../assets/styles/resources/yoigo.svg" alt="" />
				</div>
				<div
					class="card card--sm"
					[ngClass]="{ 'card--selected': operatorSelected === 'movistar' }"
					type="button"
					(click)="applyOperator('movistar')"
				>
					<img src="../../../assets/styles/resources/movistar.svg" alt="" />
				</div>
				<div
					class="card card--sm"
					[ngClass]="{ 'card--selected': operatorSelected === 'llamaya' }"
					type="button"
					(click)="applyOperator('llamaya')"
				>
					<img src="../../../assets/styles/resources/llamaya.svg" alt="" />
				</div>
				<div
					class="card card--sm"
					[ngClass]="{ 'card--selected': operatorSelected === 'lyca' }"
					type="button"
					(click)="applyOperator('lyca')"
				>
					<img src="../../../assets/styles/resources/lyca.svg" alt="" />
				</div>
				<div
					class="card card--sm"
					[ngClass]="{ 'card--selected': operatorSelected === 'lebara' }"
					type="button"
					(click)="applyOperator('lebara')"
				>
					<img src="../../../assets/styles/resources/lebara.svg" alt="" />
				</div>
				<div
					class="card card--sm"
					[ngClass]="{ 'card--selected': operatorSelected === 'bestmovil' }"
					type="button"
					(click)="applyOperator('bestmovil')"
				>
					<img src="../../../assets/styles/resources/bestmovil.svg" alt="" />
				</div>
				<div
					class="card card--sm"
					[ngClass]="{ 'card--selected': operatorSelected === 'blau' }"
					type="button"
					(click)="applyOperator('blau')"
				>
					<img src="../../../assets/styles/resources/blau.svg" alt="" />
				</div>
				<div
					class="card card--sm"
					[ngClass]="{ 'card--selected': operatorSelected === 'eplus' }"
					type="button"
					(click)="applyOperator('eplus')"
				>
					<img src="../../../assets/styles/resources/eplus.svg" alt="" />
				</div>
				<div
					class="card card--sm"
					[ngClass]="{ 'card--selected': operatorSelected === 'hablapp' }"
					type="button"
					(click)="applyOperator('hablapp')"
				>
					<img src="../../../assets/styles/resources/hablapp.svg" alt="" />
				</div>
				<div
					class="card card--sm"
					[ngClass]="{ 'card--selected': operatorSelected === 'happymovil' }"
					type="button"
					(click)="applyOperator('happymovil')"
				>
					<img src="../../../assets/styles/resources/happymovil.svg" alt="" />
				</div>
				<div
					class="card card--sm"
					[ngClass]="{ 'card--selected': operatorSelected === 'suop' }"
					type="button"
					(click)="applyOperator('suop')"
				>
					<img src="../../../assets/styles/resources/suop.svg" alt="" />
				</div>
				<div
					class="card card--sm"
					[ngClass]="{ 'card--selected': operatorSelected === 'youmobile' }"
					type="button"
					(click)="applyOperator('youmobile')"
				>
					<img src="../../../assets/styles/resources/you.svg" alt="" />
				</div>
				<div
					class="card card--sm"
					[ngClass]="{ 'card--selected': operatorSelected === 'digi' }"
					type="button"
					(click)="applyOperator('digi')"
				>
					<img src="../../../assets/styles/resources/digi.svg" alt="" />
				</div>
				<div
					class="card card--sm"
					[ngClass]="{ 'card--selected': operatorSelected === 'vodafone' }"
					type="button"
					(click)="applyOperator('vodafone')"
				>
					<img src="../../../assets/styles/resources/vodafone.svg" alt="" />
				</div>
			</div>
		</div>
	</ng-template>
</ng-container>
<ng-template #errors>
	<app-error></app-error>
</ng-template>
