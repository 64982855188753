import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CardType } from 'src/app/core/enums/card-type.enum';
import { PaymentMethod } from 'src/app/core/enums/payment-method.model';
import { PurchaseType } from 'src/app/core/enums/purchase-type.enum';
export interface Purchase {
	email?: string;
	totalAmount?: number;
	feePrice?: number;
	feeCount?: number;
	remainingAmount?: number;
	purchaseType?: PurchaseType;
	cardType?: CardType;
	roamingTotal?: number;
	romaingCount?: number;
	isRomaingActivated?: boolean;
	feeSubtitle?: string;
	productId?: string;
	feeAmount?: number;
	bonusId?: string;
	paymentMethod?: PaymentMethod;
	msisdn?: number;
	orderId?: string;
	emailCopy?: boolean;
	rechargeId?: string;
	isPaymentSuccessful?: boolean;
	isContractSended?: boolean;
	isQRsent?: boolean;
	product_code?: string;
	isFinishProcess?: boolean;
	isAnotherBuy?: boolean;
	isDistinctProduct?: boolean;
	disabledExit?: boolean;
	isClientVerified?: boolean;
}

@Injectable({
	providedIn: 'root',
})
export class PurchaseService {
	private purchaseSubject = new BehaviorSubject<Purchase>({
		email: '',
		totalAmount: 0,
		feeCount: 0,
		remainingAmount: 0,
		roamingTotal: 0,
		romaingCount: 0,
		isRomaingActivated: false,
		feeSubtitle: '',
		feeAmount: 0,
		feePrice: 0,
		isContractSended: false,
		isPaymentSuccessful: false,
		isQRsent: false,
		product_code: 'D300001',
		isFinishProcess: false,
		disabledExit: false,
		isClientVerified: false,
	});
	purchase$: Observable<Purchase> = this.purchaseSubject.asObservable();

	setPurchase(data: Purchase) {
		this.purchaseSubject.next({ ...this.purchaseSubject.value, ...data });
	}

	getPurchase() {
		return this.purchaseSubject.value;
	}

	resetPurchase() {
		this.purchaseSubject.next({
			email: '',
			totalAmount: 0,
			remainingAmount: 0,
			feeCount: 0,
			roamingTotal: 0,
			romaingCount: 0,
			isRomaingActivated: false,
			feeSubtitle: '',
			feeAmount: 0,
			feePrice: 0,
			isPaymentSuccessful: false,
		});
	}
}
