import { CommonModule, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import localeEs from '@angular/common/locales/es';
import { LOCALE_ID, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { Observable, catchError, filter, forkJoin, map, of } from 'rxjs';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './core/components/footer/footer.component';
import { HeaderComponent } from './core/components/header/header.component';
import { NavComponent } from './core/components/nav/nav.component';
import { StartComponent } from './core/components/start/start.component';
import { jwtGuard } from './core/guards/jwt.guard';
import { CancelHttpInterceptor } from './core/services/cancel-http.interceptor';
import { ErrorInterceptor } from './core/services/error.interceptor';
import { HeaderInterceptor } from './core/services/header.interceptor';
import { BuyCardModule } from './features/buy-card/buy-card.module';
import { RechargeBalanceModule } from './features/recharge-balance/recharge-balance.module';
import { RechargeBalanceService } from './features/recharge-balance/services/recharge-balance.service';
import { AccessibilityService } from './shared/services/accessibility.service';
import { ContractService } from './shared/services/contract.service';
import { KeyboardService } from './shared/services/keyboard.service';
import { PurchaseService } from './shared/services/purchase.service';
import { StepperService } from './shared/services/stepper.service';
import { TimeoutService } from './shared/services/timeout.service';
import { SharedModule } from './shared/shared.module';

class MultiHttpLoader implements TranslateLoader {
	constructor(
		private http: HttpClient,
		public resources: string[],
	) {}

	getTranslation(lang: string): Observable<any> {
		const requests = this.resources.map((path) => this.http.get(`${path}${lang}.json`).pipe(catchError(() => of(null))));

		return forkJoin(requests).pipe(
			filter((value) => {
				return !!value.filter((item) => !!item);
			}),
			map((response) => Object.assign({}, ...response)),
		);
	}
}

registerLocaleData(localeEs);
export function HttpLoaderFactory(http: HttpClient) {
	return new MultiHttpLoader(http, ['assets/i18n/']);
}
@NgModule({
	declarations: [AppComponent, StartComponent, HeaderComponent, FooterComponent, NavComponent],
	imports: [
		BrowserModule,
		ReactiveFormsModule,
		AppRoutingModule,
		HttpClientModule,
		CommonModule,
		SharedModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
		}),
		BrowserAnimationsModule,
		BuyCardModule,
		RouterModule,
		RechargeBalanceModule,
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HeaderInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ErrorInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: CancelHttpInterceptor,
			multi: true,
		},
		{ provide: LOCALE_ID, useValue: 'es' },
		StepperService,
		AccessibilityService,
		RechargeBalanceService,
		PurchaseService,
		KeyboardService,
		TimeoutService,
		ContractService,
		jwtGuard,
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
