// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	// apiUrl: 'http://192.168.40.25:8087',
	apiUrl: 'https://ms-ch-kioskos-int-s6dwsf7tnq-ew.a.run.app',
	// apiUrl: 'https://ms-ch-kioskos.lamarr.tecalis.dev',
	xApiKey: 'd21d0df1-842f-c4f0-5888-271b3c14e757',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
