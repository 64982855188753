import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class AccessibilityService {
	private accessibilitySubject = new BehaviorSubject<boolean>(false);
	private firstStepSubject = new BehaviorSubject<boolean>(true);
	accessibility$: Observable<boolean> = this.accessibilitySubject.asObservable();
	firstStep$: Observable<boolean> = this.firstStepSubject.asObservable();

	setAccessibility(hasAccessibility: boolean) {
		this.accessibilitySubject.next(hasAccessibility);
	}
	getAccessibility(): boolean {
		return this.accessibilitySubject.value;
	}

	isFirstStepFieldActivated(): boolean {
		return this.firstStepSubject.value;
	}

	changeFirstStep(): void {
		this.firstStepSubject.next(!this.firstStepSubject.value);
	}
}
