<div class="modal show">
	<div class="modal__content">
		<div class="modal__content__body">
			<div class="body__header">
				<h2>{{ 'INACTIVITY.TITLE' | translate }}</h2>
				<span>{{ 'INACTIVITY.SUBTITLE' | translate }}</span>
			</div>
		</div>
		<div class="modal__content__buttons">
			<button class="button button--filled" type="button" (click)="dialogRef.close()">
				{{ 'GENERAL.CONTINUE' | translate }}
			</button>
			<button class="button" type="button" (click)="dialogRef.close('end')">
				<span>{{ 'GENERAL.END_SESSION' | translate }} ({{ countdown }}s)</span>
			</button>
		</div>
	</div>
</div>
