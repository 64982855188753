import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, takeUntil } from 'rxjs';
import { TimeoutService } from 'src/app/shared/services/timeout.service';

@Injectable()
export class CancelHttpInterceptor implements HttpInterceptor {
	constructor(private readonly timeoutService: TimeoutService) {}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		return next.handle(request).pipe(takeUntil(this.timeoutService.onCancelPendingRequests()));
	}
}
