import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ExitModalComponent } from 'src/app/shared/components/exit-modal/exit-modal.component';
import { LanguageModalComponent } from 'src/app/shared/components/language-modal/language-modal.component';
import { AccessibilityService } from 'src/app/shared/services/accessibility.service';
import { ContractService } from 'src/app/shared/services/contract.service';
import { ErrorService } from 'src/app/shared/services/error.service';
import { IdleService } from 'src/app/shared/services/idle.service';
import { KeyboardService } from 'src/app/shared/services/keyboard.service';
import { PurchaseService } from 'src/app/shared/services/purchase.service';
import { ScannerService } from 'src/app/shared/services/scanner.service';
import { SseService } from 'src/app/shared/services/sse.service';
import { StepperService } from 'src/app/shared/services/stepper.service';
import { TicketService } from 'src/app/shared/services/ticket.service';
import { TimeoutService } from 'src/app/shared/services/timeout.service';
import { CardType } from '../../enums/card-type.enum';
import { PurchaseType } from '../../enums/purchase-type.enum';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
	step = 1;

	@Input()
	showStartFooter!: boolean;

	constructor(
		private readonly route: ActivatedRoute,
		readonly location: Location,
		private readonly dialog: MatDialog,
		readonly accessibilityService: AccessibilityService,
		private readonly router: Router,
		private readonly stepperService: StepperService,
		private readonly errorService: ErrorService,
		private readonly timeoutService: TimeoutService,
		readonly purchaseService: PurchaseService,
		private readonly keyboardService: KeyboardService,
		readonly contractService: ContractService,
		private readonly idleService: IdleService,
		private readonly scannerService: ScannerService,
		private printTicket: TicketService,
		private sseService: SseService,
	) {}

	ngOnInit(): void {
		this.step =
			Number(this.route.snapshot.paramMap.get('step')) === 0 ? 1 : Number(this.route.snapshot.paramMap.get('step'));
	}

	goBack() {
		const purchase = this.purchaseService.getPurchase();
		this.timeoutService.clearAllTimeouts();
		this.errorService.removeError();
		const request = this.errorService.getOriginalRequest();
		if (!this.location.path().includes('buy-card/1') && !this.location.path().includes('buy-card/2'))
			this.timeoutService.cancelPendingRequests();
		if (purchase.purchaseType === PurchaseType.RechargeBalance) {
			if (
				purchase.isPaymentSuccessful &&
				(this.location.path().includes('pay/3') || this.location.path().includes('pay/4'))
			) {
				return this.router.navigate(['/recharge-balance/step/22']);
			}
			if (this.location.path().includes('/recharge-balance/step/22'))
				return this.router.navigate(['/recharge-balance/step/21']);
			if (this.location.path().includes('/recharge-balance/step/21'))
				return this.router.navigate(['/recharge-balance/step/20']);
			if (this.location.path().includes('/recharge-balance/step/20')) {
				this.stepperService.setStep(-2);
				return this.router.navigate(['start/2']);
			}
		}

		if (purchase.purchaseType === PurchaseType.BuyCard) {
			if (
				purchase.cardType === CardType.Physical &&
				purchase.isPaymentSuccessful &&
				(this.location.path().includes('pay/3') || this.location.path().includes('pay/4'))
			) {
				return this.router.navigate(['/buy-card/contract/step/9']);
			}
			if (
				purchase.cardType === CardType.eSim &&
				purchase.isPaymentSuccessful &&
				(this.location.path().includes('pay/3') || this.location.path().includes('pay/11'))
			) {
				return this.router.navigate(['/buy-card/contract/step/9']);
			}
			if (purchase.cardType === CardType.eSim && purchase.isPaymentSuccessful && this.location.path().includes('pay/4')) {
				return this.router.navigate(['/pay/11']);
			}
		}
		if (this.location.path().includes('start/2') && this.accessibilityService.getAccessibility()) {
			this.accessibilityService.setAccessibility(false);
			return this.router.navigate(['start/1']);
		}
		if (this.location.path().includes('buy-card/1')) this.stepperService.setStep(1);
		if (this.location.path().includes('/buy-card/contract/step/9') && !purchase.isPaymentSuccessful) {
			if (!!purchase.isClientVerified) {
				return this.router.navigate(['/buy-card/contract/step/3']);
			}
			return this.router.navigate(['/buy-card/contract/step/7']);
		}
		if (this.location.path().includes('/buy-card/contract/step/7')) {
			if (this.contractService.getShowValidDocuments()) {
				this.contractService.setShowValidDocuments(false);
				return;
			}
		}
		if (this.location.path().includes('/buy-card/contract/step/8')) {
			if (!request?.url.includes('/create')) this.scannerService.resetScanner().subscribe();
			this.sseService.close();
			return this.router.navigate(['/buy-card/contract/step/7']);
		}
		if (this.location.path().includes('/buy-card/contract/step/7'))
			return this.router.navigate(['/buy-card/contract/step/3']);
		if (this.location.path().includes('/buy-card/contract/step/3'))
			return this.router.navigate(['/buy-card/contract/step/1']);
		if (this.location.path().includes('/buy-card/contract/step/2'))
			return this.router.navigate(['/buy-card/contract/step/1']);
		if (this.location.path().includes('/buy-card/contract/step/1'))
			return this.router.navigate([`buy-card/details/${this.purchaseService.getPurchase().productId}`]);
		if (this.location.path().includes('buy-card/details/')) return this.router.navigate([`/buy-card/2`]);
		if (this.location.path().includes('/buy-card/2')) return this.router.navigate([`/buy-card/1`]);
		if (this.location.path().includes('/buy-card/1')) return this.router.navigate([`/start/2`]);
		if (this.location.path().includes('/start/2')) return this.router.navigate([`/start/1`]);
		this.showStartFooter = false;
		return this.location.back();
	}

	openDialog() {
		this.dialog.open(LanguageModalComponent);
	}

	openExitDialog() {
		this.dialog
			.open(ExitModalComponent)
			.afterClosed()
			.subscribe((value) => {
				if (value === 'confirm') {
					let purchase = this.purchaseService.getPurchase();
					if (
						(purchase.totalAmount !== purchase.remainingAmount && purchase.remainingAmount !== 0) ||
						(!!purchase.isPaymentSuccessful && !purchase.isFinishProcess)
					) {
						this.printTicket
							.printRefundTicket(
								purchase.purchaseType === 0 ? purchase.orderId! : purchase.rechargeId!,
								purchase.purchaseType === 0 ? 'order' : 'recharge',
								'true',
							)
							.subscribe({
								next: () => {
									this.resetHardwareAndSoftware();
								},
								error: () => {
									this.errorService.setError();
								},
							});
					} else {
						this.resetHardwareAndSoftware();
					}
				}
			});
	}

	resetHardwareAndSoftware() {
		this.keyboardService.resetKeyboard();
		this.timeoutService.cancelPendingRequests();
		this.errorService.removeError();
		this.purchaseService.resetPurchase();
		this.accessibilityService.setAccessibility(false);
		this.stepperService.setStep(-1);
		this.timeoutService.clearAllTimeouts();
		this.idleService.clearHardware().subscribe();
		this.router.navigate(['/start/1']);
	}

	accessibility() {
		if (this.location.path().includes('start/1')) this.router.navigate(['/start/2']);

		this.accessibilityService.setAccessibility(!this.accessibilityService.getAccessibility());
	}

	openHelpPage() {
		this.router.navigate(['help']);
	}
}
