<ng-container *ngIf="!(errorService.hasError$ | async); else errors">
	<ng-container *ngIf="step === 1">
		<div class="main__header">
			<h1>{{ 'CONTRACT.CONTRACT_TITLE_STEP_1' | translate }}</h1>
			<span>{{ (purchaseService.purchase$ | async)!.feeSubtitle ?? '' }}</span>
		</div>
		<div *ngIf="loadingBlockService.isLoading$ | async" class="loader">
			<svg class="spinner" xmlns="http://www.w3.org/2000/svg" width="240" height="240" viewBox="0 0 240 240" fill="none">
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M120 56C84.6538 56 56 84.6538 56 120C56 155.346 84.6538 184 120 184C155.346 184 184 155.346 184 120C184 110.814 182.07 102.101 178.6 94.2253L193.242 87.7747C197.59 97.6437 200 108.551 200 120C200 164.183 164.183 200 120 200C75.8172 200 40 164.183 40 120C40 75.8172 75.8172 40 120 40V56Z"
					fill="#FF7900"
				/>
			</svg>
			<span>{{ 'CONTRACT.LOAGIND_CONTRACT' | translate }}</span>
		</div>
		<div *ngIf="!(loadingBlockService.isLoading$ | async)" class="main__content">
			<div class="document">
				<pdf-viewer
					[src]="contractPdfSource"
					[render-text]="true"
					[original-size]="false"
					style="width: 900px; height: 1300px"
				></pdf-viewer>
			</div>
		</div>
	</ng-container>
	<ng-container *ngIf="step === 2">
		<div class="main__header">
			<h1>{{ 'CONTRACT.CONTRACT_POLICY' | translate }}</h1>
			<span>{{ 'CONTRACT.INFO_POLICY' | translate }}</span>
		</div>
		<div class="main__content">
			<div class="item-group item-group--sm">
				<div class="item item--sm">
					<div class="item__body">
						<div class="item__body__title">{{ 'CONTRACT.RESPONSIBLE' | translate }}</div>
						<div class="item__body__text">{{ 'CONTRACT.ORANGE' | translate }}</div>
					</div>
				</div>
				<div class="item item--sm">
					<div class="item__body">
						<div class="item__body__title">{{ 'CONTRACT.PURPOSE' | translate }}</div>
						<div class="item__body__text">
							{{ 'CONTRACT.CONTRACT_INFO' | translate }}
						</div>
					</div>
				</div>
				<div class="item item--sm">
					<div class="item__body">
						<div class="item__body__title">{{ 'CONTRACT.LEGITIMATION' | translate }}</div>
						<div class="item__body__text">
							{{ 'CONTRACT.LEGITIMATION_INFO' | translate }}
						</div>
					</div>
				</div>
				<div class="item item--sm">
					<div class="item__body">
						<div class="item__body__title">{{ 'CONTRACT.RECIPIENTS' | translate }}</div>
						<div class="item__body__text">
							{{ 'CONTRACT.RECIPIENTS_INFO' | translate }}
						</div>
					</div>
				</div>
				<div class="item item--sm">
					<div class="item__body">
						<div class="item__body__title">{{ 'CONTRACT.RIGHTS' | translate }}</div>
						<div class="item__body__text">
							{{ 'CONTRACT.RIGHTS_INFO' | translate }}
						</div>
					</div>
				</div>
				<div class="item item--sm">
					<div class="item__body">
						<div class="item__body__title">{{ 'CONTRACT.ADDITIONAL_INFORMATION' | translate }}</div>
						<div class="item__body__text">
							{{ 'CONTRACT.ADDITIONAL_INFORMATION_INFO' | translate }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</ng-container>
	<ng-container *ngIf="step === 3">
		<ng-container *ngIf="!accessibilityService.getAccessibility()">
			<div class="main__header">
				<h1>{{ 'CONTRACT.DOCUMENT_COPY_TITLE' | translate }}</h1>
				<span>{{ 'CONTRACT.CONTRACT_EXHIBIT' | translate }}</span>
			</div>
			<div class="main__content">
				<div class="grid grid-i2">
					<div class="form-group" [formGroup]="form">
						<div class="form-group__text">
							<label> {{ 'GENERAL.EMAIL' | translate }} </label>
						</div>
						<div class="form-group__input">
							<input
								#inputEmail
								type="text"
								class="input"
								name="email"
								[(ngModel)]="email"
								formControlName="email"
								(focus)="onFocus('email', 'inputEmail')"
								(input)="onFocus('email', 'inputEmail')"
								[placeholder]="'CONTRACT.EMAIL' | translate"
								autocomplete="off"
								[value]="email"
								(click)="onFocus('email', 'inputEmail')"
							/>
							<button class="button-icon">
								<i
									*ngIf="(keyboardService.keyboard$ | async).formValues.email"
									class="icon-close"
									(click)="deleteField('email')"
								></i>
							</button>
						</div>
					</div>
					<div class="form-group" [formGroup]="form">
						<div class="form-group__text">
							<label for=""> {{ 'GENERAL.CONFIRM_EMAIL' | translate }} </label>
						</div>
						<div class="form-group__input">
							<input
								#inputRepeatEmail
								type="text"
								[ngClass]="{
									'input--error': (keyboardService.keyboard$ | async).formErrors?.repeat_email?.valuesDoNotMatch,
								}"
								class="input"
								name="repeat_email"
								[(ngModel)]="repeat_email"
								formControlName="repeat_email"
								[placeholder]="'CONTRACT.EMAIL' | translate"
								(focus)="onFocus('repeat_email', 'inputRepeatEmail')"
								(input)="onFocus('repeat_email', 'inputRepeatEmail')"
								autocomplete="off"
								[value]="repeat_email"
								(click)="onFocus('repeat_email', 'inputRepeatEmail')"
								[ngClass]="{
									'input--error':
										(keyboardService.keyboard$ | async).formValues.repeat_email &&
										((keyboardService.keyboard$ | async).formValues.repeat_email.length ===
											(keyboardService.keyboard$ | async).formValues.email.length ||
											(keyboardService.keyboard$ | async).formValues.repeat_email.length >
												(keyboardService.keyboard$ | async).formValues.email.length) &&
										!emailMatch,
								}"
							/>
							<button class="button-icon">
								<i
									*ngIf="(keyboardService.keyboard$ | async).formValues.repeat_email"
									class="icon-close"
									(click)="deleteField('repeat_email')"
								></i>
							</button>
						</div>
						<div
							*ngIf="
								(keyboardService.keyboard$ | async).formValues.repeat_email &&
								((keyboardService.keyboard$ | async).formValues.repeat_email.length ===
									(keyboardService.keyboard$ | async).formValues.email.length ||
									(keyboardService.keyboard$ | async).formValues.repeat_email.length >
										(keyboardService.keyboard$ | async).formValues.email.length) &&
								!emailMatch
							"
							class="form-group__error-message"
						>
							<i class="icon-info"></i>
							{{ 'ERROR.EMAIL_ERROR_MESSAGE' | translate }}
						</div>
						<div
							*ngIf="
								!!(keyboardService.keyboard$ | async).formErrors['email']?.pattern &&
								(keyboardService.keyboard$ | async).formValues['repeat_email'] !== ''
							"
							class="form-group__error-message"
						>
							<i class="icon-info"></i>
							{{ 'ERROR.EMAIL_FORMAT_ERROR_MESSAGE' | translate }}
						</div>
					</div>
				</div>
			</div>
		</ng-container>
		<ng-container *ngIf="accessibilityService.getAccessibility()">
			<div class="main__header">
				<h1>{{ 'CONTRACT.DOCUMENT_COPY_TITLE' | translate }}</h1>
				<span>{{ 'CONTRACT.CONTRACT_EXHIBIT' | translate }}</span>
			</div>
			<div class="main__content">
				<div class="grid">
					<div *ngIf="accessibilityService.isFirstStepFieldActivated()" class="form-group" [formGroup]="form">
						<div class="form-group__text">
							<label> {{ 'GENERAL.EMAIL' | translate }} </label>
						</div>
						<div class="form-group__input">
							<input
								#inputEmail
								type="text"
								class="input"
								name="email"
								formControlName="email"
								(focus)="onFocus('email', 'inputEmail')"
								(input)="onFocus('email', 'inputEmail')"
								(click)="onFocus('email', 'inputEmail')"
								autocomplete="off"
							/>
							<button class="button-icon">
								<i
									*ngIf="(keyboardService.keyboard$ | async).formValues.email"
									class="icon-close"
									(click)="deleteField('email')"
								></i>
							</button>
						</div>
					</div>
					<div *ngIf="!accessibilityService.isFirstStepFieldActivated()" class="form-group" [formGroup]="form">
						<div class="form-group__text">
							<label for=""> {{ 'GENERAL.CONFIRM_EMAIL' | translate }} </label>
						</div>
						<div class="form-group__input">
							<input
								#inputRepeatEmail
								type="text"
								[ngClass]="{
									'input--error':
										(keyboardService.keyboard$ | async).formValues.repeat_email &&
										((keyboardService.keyboard$ | async).formValues.repeat_email.length ===
											(keyboardService.keyboard$ | async).formValues.email.length ||
											(keyboardService.keyboard$ | async).formValues.repeat_email.length >
												(keyboardService.keyboard$ | async).formValues.email.length) &&
										!emailMatch,
								}"
								class="input"
								name="repeat_email"
								formControlName="repeat_email"
								[placeholder]="'CONTRACT.EMAIL' | translate"
								(focus)="onFocus('repeat_email', 'inputRepeatEmail')"
								(input)="onFocus('repeat_email', 'inputRepeatEmail')"
								(click)="onFocus('repeat_email', 'inputRepeatEmail')"
								autocomplete="off"
							/>
							<button class="button-icon">
								<i
									*ngIf="(keyboardService.keyboard$ | async).formValues.repeat_email"
									class="icon-close"
									(click)="deleteField('repeat_email')"
								></i>
							</button>
						</div>

						<div
							*ngIf="
								(keyboardService.keyboard$ | async).formValues.repeat_email &&
								((keyboardService.keyboard$ | async).formValues.repeat_email.length ===
									(keyboardService.keyboard$ | async).formValues.email.length ||
									(keyboardService.keyboard$ | async).formValues.repeat_email.length >
										(keyboardService.keyboard$ | async).formValues.email.length) &&
								!emailMatch
							"
							class="form-group__error-message"
						>
							<i class="icon-info"></i>
							{{ 'ERROR.EMAIL_ERROR_MESSAGE' | translate }}
						</div>
						<div
							*ngIf="
								!!(keyboardService.keyboard$ | async).formErrors['email']?.pattern &&
								(keyboardService.keyboard$ | async).formValues['repeat_email'] !== ''
							"
							class="form-group__error-message"
						>
							<i class="icon-info"></i>
							{{ 'ERROR.EMAIL_FORMAT_ERROR_MESSAGE' | translate }}
						</div>
					</div>
				</div>
			</div>
		</ng-container>
	</ng-container>

	<ng-container *ngIf="step === 4">
		<div class="loader">
			<svg xmlns="http://www.w3.org/2000/svg" class="animate-check" viewBox="0 0 24 24">
				<g stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10">
					<circle class="success-circle-outline" cx="12" cy="12" r="11.5" />
					<circle class="success-circle-fill" cx="12" cy="12" r="11.5" />
					<polyline class="success-tick" points="17,8.5 9.5,15.5 7,13" />
				</g>
			</svg>
			<span>{{ 'GENERAL.SENT_EMAIL' | translate }} </span>
		</div>
	</ng-container>
	<ng-container *ngIf="step === 5">
		<div class="main__header">
			<h1>{{ 'CONTRACT.CONTRACT_POLICY' | translate }}</h1>
		</div>
		<div class="main__content">
			<div *ngIf="loadingBlockService.isLoading$ | async" class="loader">
				<svg class="spinner" xmlns="http://www.w3.org/2000/svg" width="240" height="240" viewBox="0 0 240 240" fill="none">
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M120 56C84.6538 56 56 84.6538 56 120C56 155.346 84.6538 184 120 184C155.346 184 184 155.346 184 120C184 110.814 182.07 102.101 178.6 94.2253L193.242 87.7747C197.59 97.6437 200 108.551 200 120C200 164.183 164.183 200 120 200C75.8172 200 40 164.183 40 120C40 75.8172 75.8172 40 120 40V56Z"
						fill="#FF7900"
					/>
				</svg>
			</div>
			<div *ngIf="!(loadingBlockService.isLoading$ | async)" class="document">
				<pdf-viewer
					[src]="policyPdfSource"
					[render-text]="true"
					[original-size]="false"
					style="width: 900px; height: 1168px"
				></pdf-viewer>
			</div>
		</div>
	</ng-container>

	<ng-container *ngIf="step === 7">
		<ng-container *ngIf="contractService.showValidDocuments$ | async; else showStep7">
			<div class="main__header">
				<h1>{{ 'IDENTITY.WHAT_DOCUMENT_CAN_I_USE' | translate }}</h1>
			</div>
			<div class="list list--documents">
				<div class="list__item">
					<h4>{{ 'IDENTITY.VALID_DOCUMENTS.DOCUMENTS_OTHER_COUNTRY' | translate }}</h4>
					<div class="image-group-wrapper">
						<div class="image-group">
							<img src="../../../../../assets/styles/resources/passaporte.svg" alt="passport" />
							<div class="image-group__text">{{ 'IDENTITY.PASSPORT' | translate }}</div>
						</div>
						<div class="image-group">
							<img src="../../../../../assets/styles/resources/id-ue.svg" alt="dni-ue" />
							<div class="image-group__text">{{ 'IDENTITY.VALID_DOCUMENTS.DOCUMENT_ANOTHER_UE_COUNTRY' | translate }}</div>
						</div>
					</div>
				</div>
				<div class="list__item">
					<h4>{{ 'IDENTITY.VALID_DOCUMENTS.DOCUMENTS_SPAIN' | translate }}</h4>
					<div class="image-group-wrapper">
						<div class="image-group">
							<img src="../../../../../assets/styles/resources/dni.svg" alt="DNI" />
							<div class="image-group__text">{{ 'IDENTITY.VALID_DOCUMENTS.DNI' | translate }}</div>
						</div>
						<div class="image-group">
							<img src="../../../../../assets/styles/resources/residencia.svg" alt="Residencia" />
							<div class="image-group__text">{{ 'IDENTITY.VALID_DOCUMENTS.RESIDENCE_CARD_NO_UE' | translate }}</div>
						</div>
						<div class="image-group">
							<img src="../../../../../assets/styles/resources/passport-es.svg" alt="pasaporte es" />
							<div class="image-group__text">{{ 'IDENTITY.VALID_DOCUMENTS.PASSPORT' | translate }}</div>
						</div>
					</div>
				</div>
			</div>
			<div class="alert">
				<i class="icon-info"></i>
				<span>{{ 'IDENTITY.ORIGINAL_DOCUMENTS' | translate }}</span>
			</div>
		</ng-container>
		<ng-template #showStep7>
			<div class="main__header">
				<h1>{{ 'IDENTITY.SELECT_TYPE_IDENTITY' | translate }}</h1>
			</div>
			<div class="card-group">
				<div class="card" type="button" (click)="selectOption(8, IdentityType.Passport)">
					<i class="icon-passport icon--lg"></i>
					<div class="card__text">
						<h3>{{ 'IDENTITY.PASSPORT' | translate }}</h3>
					</div>
				</div>
				<div class="card" type="button" (click)="selectOption(8, IdentityType.IdentityDocument)">
					<i class="icon-dni icon--lg"></i>
					<div class="card__text">
						<h3>{{ 'IDENTITY.IDENTITY_DOCUMENT' | translate }}</h3>
						<span>{{ 'IDENTITY.RESIDENCE_CARD' | translate }}</span>
					</div>
				</div>
			</div>
			<div class="main__link">
				<a (click)="showValidDocuments(true)">{{ 'IDENTITY.WHAT_DOCUMENT_CAN_I_USE' | translate }}</a>
			</div>
		</ng-template>
	</ng-container>

	<ng-container *ngIf="step === 8">
		<ng-container *ngIf="IdentityType[identityOption] === 'Passport'; else showIdentityDocument">
			<div class="loader" *ngIf="phasePassport === 1">
				<ng-lottie [options]="{ path: '../../../../../assets/styles/resources/pasaporte-parte-1.json' }"></ng-lottie>
				<span [innerHTML]="'IDENTITY.SCAN_PAGE_PHOTO' | translate"></span>
			</div>
			<div class="loader" *ngIf="phasePassport === 2">
				<ng-lottie [options]="{ path: '../../../../../assets/styles/resources/pasaporte-parte-2.json' }"></ng-lottie>
				<span [innerHTML]="'IDENTITY.SURE_PASSPORT_LEFT' | translate"></span>
			</div>
			<div class="loader" *ngIf="phasePassport === 3">
				<ng-lottie [options]="{ path: '../../../../../assets/styles/resources/pasaporte-parte-3.json' }"></ng-lottie>
				<span [innerHTML]="'IDENTITY.SCAN_SOUND_OK' | translate"></span>
			</div>
			<div class="loader" *ngIf="phasePassport === 4">
				<svg class="spinner" xmlns="http://www.w3.org/2000/svg" width="240" height="240" viewBox="0 0 240 240" fill="none">
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M120 56C84.6538 56 56 84.6538 56 120C56 155.346 84.6538 184 120 184C155.346 184 184 155.346 184 120C184 110.814 182.07 102.101 178.6 94.2253L193.242 87.7747C197.59 97.6437 200 108.551 200 120C200 164.183 164.183 200 120 200C75.8172 200 40 164.183 40 120C40 75.8172 75.8172 40 120 40V56Z"
						fill="#FF7900"
					/>
				</svg>
				<div class="loader__text">
					<h3 [innerHTML]="'GENERAL.VERIFICATION' | translate"></h3>
					<span>{{ 'IDENTITY.MAY_TAKE_MINUTES' | translate }}</span>
				</div>
			</div>
			<div class="loader" *ngIf="phasePassport === 5">
				<svg xmlns="http://www.w3.org/2000/svg" class="animate-check" viewBox="0 0 24 24">
					<g stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10">
						<circle class="success-circle-outline" cx="12" cy="12" r="11.5" />
						<circle class="success-circle-fill" cx="12" cy="12" r="11.5" />
						<polyline class="success-tick" points="17,8.5 9.5,15.5 7,13" />
					</g>
				</svg>
				<span [innerHTML]="'IDENTITY.VERIFICATION_SUCCESS' | translate"></span>
			</div>
		</ng-container>
		<ng-template #showIdentityDocument>
			<ng-container *ngIf="IdentityType[identityOption] === 'IdentityDocument'">
				<!-- FRONT -->
				<div class="loader" *ngIf="phaseDocumentIdentity === 1">
					<ng-lottie [options]="{ path: '../../../../../assets/styles/resources/IDfrontal-parte-1.json' }"></ng-lottie>
					<span [innerHTML]="'IDENTITY.SCAN_PAGE_PHOTO_DOCUMENT' | translate"></span>
				</div>
				<div class="loader" *ngIf="phaseDocumentIdentity === 2">
					<ng-lottie [options]="{ path: '../../../../../assets/styles/resources/IDfrontal-parte-2.json' }"></ng-lottie>
					<span [innerHTML]="'IDENTITY.SURE_DOCUMENT_LEFT' | translate"></span>
				</div>
				<div class="loader" *ngIf="phaseDocumentIdentity === 3">
					<ng-lottie [options]="{ path: '../../../../../assets/styles/resources/IDfrontal-parte-3.json' }"></ng-lottie>
					<span [innerHTML]="'IDENTITY.SCAN_SOUND_OK' | translate"></span>
				</div>

				<!-- BACK -->
				<div class="loader" *ngIf="phaseDocumentIdentity === 4">
					<ng-lottie [options]="{ path: '../../../../../assets/styles/resources/IDtrasera-parte-1.json' }"></ng-lottie>
					<span [innerHTML]="'IDENTITY.SCAN_IDENTITY_DOCUMENT_PHOTO_BACK' | translate"></span>
				</div>
				<div class="loader" *ngIf="phaseDocumentIdentity === 5">
					<ng-lottie [options]="{ path: '../../../../../assets/styles/resources/IDtrasera-parte-2.json' }"></ng-lottie>
					<span [innerHTML]="'IDENTITY.SURE_DOCUMENT_LEFT' | translate"></span>
				</div>
				<div class="loader" *ngIf="phaseDocumentIdentity === 6">
					<ng-lottie [options]="{ path: '../../../../../assets/styles/resources/IDtrasera-parte-3.json' }"></ng-lottie>
					<span [innerHTML]="'IDENTITY.SCAN_SOUND_OK' | translate"></span>
				</div>

				<div class="loader" *ngIf="phaseDocumentIdentity === 7">
					<svg class="spinner" xmlns="http://www.w3.org/2000/svg" width="240" height="240" viewBox="0 0 240 240" fill="none">
						<path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M120 56C84.6538 56 56 84.6538 56 120C56 155.346 84.6538 184 120 184C155.346 184 184 155.346 184 120C184 110.814 182.07 102.101 178.6 94.2253L193.242 87.7747C197.59 97.6437 200 108.551 200 120C200 164.183 164.183 200 120 200C75.8172 200 40 164.183 40 120C40 75.8172 75.8172 40 120 40V56Z"
							fill="#FF7900"
						/>
					</svg>
					<div class="loader__text">
						<h3 [innerHTML]="'GENERAL.VERIFICATION' | translate"></h3>
						<span>{{ 'IDENTITY.MAY_TAKE_MINUTES' | translate }}</span>
					</div>
				</div>
				<div class="loader" *ngIf="phaseDocumentIdentity === 8">
					<svg xmlns="http://www.w3.org/2000/svg" class="animate-check" viewBox="0 0 24 24">
						<g stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10">
							<circle class="success-circle-outline" cx="12" cy="12" r="11.5" />
							<circle class="success-circle-fill" cx="12" cy="12" r="11.5" />
							<polyline class="success-tick" points="17,8.5 9.5,15.5 7,13" />
						</g>
					</svg>
					<span [innerHTML]="'IDENTITY.VERIFICATION_SUCCESS' | translate"></span>
				</div>
			</ng-container>
		</ng-template>
	</ng-container>

	<ng-container *ngIf="step === 9">
		<div class="main__header">
			<h1><i class="icon-sim icon--md"></i> {{ 'GENERAL.YOUR_ORDER' | translate }}</h1>
		</div>
		<div class="main__content" *ngIf="purchaseService.purchase$ | async as purchase">
			<div class="list">
				<div class="list__item">
					<div class="list__item__product">
						{{
							(purchaseService.purchase$ | async)?.cardType === CardType.Physical
								? ('CONTRACT.SIM_PHYSIC_PREPAID' | translate)
								: ('CONTRACT.ESIM_PREPAID' | translate)
						}}
					</div>
					<div class="list__item__details">
						<div class="details__name">{{ purchase.feeSubtitle }}</div>
						<div class="details__info" [formGroup]="form">
							<ng-container *ngIf="!(purchaseService.purchase$ | async)?.isPaymentSuccessful">
								<button class="button button--xs" (click)="navigateToFeesList()">
									{{ 'GENERAL.CHANGE' | translate }}
								</button>
								<span>
									{{ '1ud' }}
								</span>
							</ng-container>
							<h4>{{ purchase.feeAmount }}€</h4>
						</div>
					</div>
				</div>
				<div class="list__item" *ngIf="purchase.isRomaingActivated">
					<div class="list__item__product">{{ 'GENERAL.BOND' | translate }}</div>
					<div class="list__item__details">
						<div class="details__name">{{ 'GENERAL.BOND_ROAMING_PLUS_WEEK' | translate }}</div>
						<div class="details__info">
							<button class="button button--xs" (click)="removeRoaming()">{{ 'GENERAL.DELETE' | translate }}</button>
							<h5>{{ purchase.feeCount }} uds</h5>
							<span>{{ purchase.feePrice }}€</span>
							<h4>{{ purchase.feeAmount }}€</h4>
						</div>
					</div>
				</div>
			</div>
		</div>
	</ng-container>
</ng-container>
<ng-template #errors>
	<app-error></app-error>
</ng-template>
