import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { LottieModule } from 'ngx-lottie';
import { TruncatePipe } from 'src/app/core/pipes/truncate.pipe';
import { SharedModule } from 'src/app/shared/shared.module';
import { BuyCardRoutingModule } from './buy-card-routing.module';
import { BuyCardComponent } from './buy-card.component';
import { CardDetailsComponent } from './components/card-details/card-details.component';
import { ContractComponent } from './components/contract/contract.component';
import { FeeDetailsModalComponent } from './components/fee-details-modal/fee-details-modal.component';
import { RomaingModalComponent } from './components/romaing-modal/romaing-modal.component';
import { BuyCardService } from './services/buy-card.service';

export function playerFactory() {
	return import('lottie-web');
}

@NgModule({
	declarations: [
		BuyCardComponent,
		CardDetailsComponent,
		RomaingModalComponent,
		FeeDetailsModalComponent,
		ContractComponent,
		TruncatePipe,
	],
	imports: [
		CommonModule,
		RouterModule,
		SharedModule,
		TranslateModule,
		MatDialogModule,
		BuyCardRoutingModule,
		FormsModule,
		ReactiveFormsModule,
		PdfViewerModule,
		LottieModule.forRoot({ player: playerFactory }),
	],
	providers: [BuyCardService],
	bootstrap: [],
})
export class BuyCardModule {}
