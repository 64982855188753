import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AlertComponent } from './components/alert/alert.component';
import { ButtonComponent } from './components/button/button.component';
import { CardComponent } from './components/card/card.component';
import { ErrorComponent } from './components/error/error.component';
import { ExitModalComponent } from './components/exit-modal/exit-modal.component';
import { HelpComponent } from './components/help/help.component';
import { InactivityDialogComponent } from './components/inactivity-dialog/inactivity-dialog.component';
import { ItemComponent } from './components/item/item.component';
import { KeyboardComponent } from './components/keyboard/keyboard.component';
import { LanguageModalComponent } from './components/language-modal/language-modal.component';
import { MailService } from './services/mail.service';
import { TicketService } from './services/ticket.service';
import { ValidationService } from './services/validation.service';

@NgModule({
	declarations: [
		ButtonComponent,
		CardComponent,
		ItemComponent,
		AlertComponent,
		LanguageModalComponent,
		ExitModalComponent,
		KeyboardComponent,
		ErrorComponent,
		HelpComponent,
		InactivityDialogComponent,
	],
	imports: [CommonModule, RouterModule, TranslateModule],
	exports: [
		ButtonComponent,
		CardComponent,
		ItemComponent,
		AlertComponent,
		LanguageModalComponent,
		ExitModalComponent,
		KeyboardComponent,
		ErrorComponent,
	],
	providers: [ValidationService, MailService, TicketService],
	bootstrap: [],
})
export class SharedModule {}
