import { Component, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { LanguageService } from 'src/app/core/services/language.service';
import { AccessibilityService } from '../../services/accessibility.service';

@Component({
	selector: 'app-language-modal',
	templateUrl: './language-modal.component.html',
	styleUrls: ['./language-modal.component.scss'],
})
export class LanguageModalComponent implements OnInit, OnDestroy {
	dialogRef: MatDialogRef<LanguageModalComponent>;
	languageOptions: any[] = [];

	private destroy$ = new Subject<void>();

	constructor(
		dialogRef: MatDialogRef<LanguageModalComponent>,
		private el: ElementRef,
		private renderer: Renderer2,
		private readonly accessibilityService: AccessibilityService,
		readonly languageService: LanguageService,
		private router: Router,
		private readonly dialog: MatDialog,
	) {
		this.dialogRef = dialogRef;
		this.languageService.allLanguages$.pipe(takeUntil(this.destroy$)).subscribe({
			next: (languages) => {
				this.languageOptions = languages;
			},
			error: () => {
				this.languageOptions = [
					{
						code: 'es',
						label: 'Español',
						order: 1,
					},
					{
						code: 'en',
						label: 'English',
						order: 2,
					},
				];
			},
		});
	}

	ngOnInit(): void {
		if (this.accessibilityService.getAccessibility())
			this.renderer.addClass(this.el.nativeElement, 'modal-accessibility');
	}

	trackByCode(index: any, lang: any): string {
		return lang.code;
	}

	selectLanguage(option: string) {
		this.languageService.setLanguage(option);
		this.dialog.closeAll();
		if (this.router.url.includes('/start/1')) this.router.navigate(['/start/', 2]);
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
