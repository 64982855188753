import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LanguageService } from 'src/app/core/services/language.service';
import { environment } from 'src/environments/environment';
import { PurchaseService } from './purchase.service';

@Injectable()
export class MailService {
	protected http: HttpClient;

	constructor(
		http: HttpClient,
		private readonly purchaseService: PurchaseService,
		private readonly languageService: LanguageService,
	) {
		this.http = http;
	}

	sendContractEmail(): Observable<any> {
		const purchase = this.purchaseService.getPurchase();
		const lang = this.languageService.getLanguage();
		return this.http.post<any>(environment.apiUrl + `/mailing/send-contract-annex`, {
			email: purchase.email,
			product_id: purchase.productId,
			lang,
		});
	}

	sendOrderTicketEmail(): Observable<any> {
		const purchase = this.purchaseService.getPurchase();
		return this.http.post<any>(environment.apiUrl + `/mailing/send-ticket`, {
			email: purchase.email,
			order_id: purchase.orderId,
			contract_annex_sended: purchase.isContractSended,
		});
	}

	sendRechargeTicketEmail(): Observable<any> {
		const purchase = this.purchaseService.getPurchase();
		return this.http.post<any>(environment.apiUrl + `/mailing/send-recharge-ticket`, {
			email: purchase.email,
			recharge_id: purchase.rechargeId,
		});
	}

	sendeSIMEmail(): Observable<any> {
		const purchase = this.purchaseService.getPurchase();
		return this.http.post<any>(environment.apiUrl + `/mailing/send-esim`, {
			email: purchase.email,
			order_id: purchase.orderId,
			contract_annex_sended: purchase.isContractSended,
		});
	}
}
