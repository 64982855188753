import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import Keyboard, { KeyboardLayoutObject } from 'simple-keyboard';
import { AccessibilityService } from '../../services/accessibility.service';
import { KeyboardData, KeyboardService } from '../../services/keyboard.service';
export enum KeyboardType {
	IOS = 'hg-theme-default hg-theme-ios',
	NUMERIC = 'hg-theme-default hg-layout-numeric numeric-theme',
	RUSSIAN = '',
}

export function getKeyboardCharacters(Type: KeyboardType) {
	return Type === KeyboardType.IOS
		? {
				default: [
					'1 2 3 4 5 6 7 8 9 0',
					'q w e r t y u i o p',
					'a s d f g h j k l',
					'z x c v b n m - . {bksp}',
					'{alt} {space} {@} {com}',
				],
				shift: [
					'1 2 3 4 5 6 7 8 9 0',
					'Q W E R T Y U I O P',
					'A S D F G H J K L',
					'{shiftactivated} Z X C V B N M , . {bksp}',
					'{alt} {space}',
				],
				alt: ['1 2 3 4 5 6 7 8 9 0', `@ # $ & * ( ) ' " _`, '% - + = / ; : ! ? {bksp}', '{default} {space}'],
			}
		: ({
				default: ['1 2 3', '4 5 6', '7 8 9', ' 0 {bksp}'],
			} as KeyboardLayoutObject);
}

export function matchValuesValidator(matchTo: string): ValidatorFn {
	return (control: AbstractControl): { [key: string]: boolean } | null => {
		const formGroup = control.parent as FormGroup;
		if (formGroup) {
			const matchControl = formGroup.controls[matchTo];
			if (matchControl && matchControl.value !== control.value) {
				return { valuesDoNotMatch: true };
			}
		}
		return null;
	};
}
@Component({
	selector: 'app-keyboard',
	templateUrl: './keyboard.component.html',
	styleUrls: ['./keyboard.component.scss'],
})
export class KeyboardComponent implements OnInit, OnDestroy {
	value = '';
	keyboard!: Keyboard;
	inputName = '';

	inputIndex = 0;
	keyboardType!: string;
	form!: FormGroup;

	private destroy$ = new Subject<void>();

	constructor(
		private readonly fb: FormBuilder,
		private readonly keyboardService: KeyboardService,
		private readonly accessibilityService: AccessibilityService,
	) {}

	ngOnInit(): void {
		this.accessibilityService.firstStep$.pipe(takeUntil(this.destroy$)).subscribe({
			next: (value) => {
				const data = this.keyboardService.getKeyboarData();
				this.inputName = value
					? data.formFields.includes('email')
						? 'email'
						: 'phone'
					: data.formFields.includes('email')
						? 'repeat_email'
						: 'phoneRepeat';
				this.inputIndex = 0;
			},
			error: () => {},
		});

		this.buildForm(this.keyboardService.getKeyboarData());
		this.inputName = this.keyboardService.getKeyboarData().fieldFocused;
		this.keyboardService.keyboard$.pipe(takeUntil(this.destroy$)).subscribe({
			next: (value) => {
				if (value.resetValues) {
					this.form.get(value.fieldFocused)?.patchValue(null);
					this.form.get(value.fieldFocused)?.updateValueAndValidity();
					this.keyboardService.setKeyboarData({
						resetValues: false,
						formErrors: {
							repeat_email: this.form.get(this.inputName)?.errors,
							phoneRepeat: this.form.get(this.inputName)?.errors,
						},
						isValid: false,
					});
				}
			},
			error: () => {},
		});
	}

	buildForm(keyboardData: KeyboardData): void {
		const group: any = {};
		keyboardData.formFields?.forEach((field, index) => {
			if (index === 0) this.inputName = field;
			group[field] = ['', this.getValidators(field)];
		});
		this.form = this.fb.group(group);
	}

	ngAfterViewInit() {
		const keyboardData = this.keyboardService.getKeyboarData();
		this.keyboard = new Keyboard({
			onKeyPress: (button) => this.onKeyPress(button),
			onChange: (input) => this.onInputChangeValue(input),
			theme: keyboardData.theme,
			layout: getKeyboardCharacters(keyboardData.theme),
			display: {
				'{alt}': '?123',
				'{com}': '.com',
				'{@}': '@',
				'{shiftactivated}': '⇧',
				'{bksp}': '⌫',
				'{space}': ' ',
				'{default}': 'ABC',
				'{back}': '⇦',
			},
		});

		this.addInputFocusListeners();
	}

	addInputFocusListeners() {
		const inputs = document.querySelectorAll('input');
		inputs.length > 1
			? inputs.forEach((input: any, index: any) => {
					input.addEventListener('focus', (event: any) => {
						const target = event.target as HTMLInputElement;
						this.inputName = target.name;
						this.inputIndex = index;
						this.keyboard.setInput('');
						if (this.inputName === 'repeat_email') this.form.get('email')?.updateValueAndValidity();
					});
				})
			: (this.inputName = inputs[0].name);
	}

	onKeyPress(text: string) {
		this.keyboardService.emitKeyboardEvent(this.keyboardService.getKeyboarData().fieldFocused);
		const regex = /\{(.*?)\}/;
		const result = text.match(regex)!;
		if (result && result[1].includes('com')) text = '.com';
		if (result && result[1].includes('@')) text = '@';
		if (!text.includes('{')) {
			this.form
				.get(this.inputName)
				?.patchValue(this.form.get(this.inputName)?.value ? this.form.get(this.inputName)?.value.concat(text) : text);
			this.form.get(this.inputName)?.markAsDirty();
			if (!this.form.valid) {
				this.form.get('email')?.updateValueAndValidity();
				if (this.form.get('repeat_email')?.value !== '') this.form.get('repeat_email')?.updateValueAndValidity();
				this.form.get('phoneRepeat')?.updateValueAndValidity();
				this.form.get('phone')?.updateValueAndValidity();
				this.keyboardService.setKeyboarData({
					isValid: false,
					formErrors: {
						repeat_email: this.form.get('repeat_email')?.errors ? this.form.get('repeat_email')?.errors : null,
						email: this.form.get('email')?.errors,
						phone: this.form.get('phone')?.errors,
						phoneRepeat: this.form.get('phoneRepeat')?.errors,
					},
				});
			}
			if (this.form.valid) this.keyboardService.setKeyboarData({ isValid: true });
			return this.keyboardService.setKeyboarForm(this.form.getRawValue());
		}
		if (result[1] === 'shiftactivated') result[1] = 'default';
		if (result[1] === 'bksp') {
			let repeatEmailError = this.form.get('repeat_email')?.errors ? this.form.get('repeat_email')?.errors : null;
			const emailError = this.form.get('email')?.errors ? this.form.get('email')?.errors : null;
			if (emailError?.['pattern']) repeatEmailError = null;
			this.keyboardService.setKeyboarData({
				isValid: false,
				formErrors: {
					repeat_email: repeatEmailError,
					email: emailError,
					phone: {
						valuesDoNotMatch: true,
						pattern: {
							requiredPattern: '^[0-9]{9}$',
							actualValue: this.form.get('phone')?.value,
						},
					},
					phoneRepeat: {
						valuesDoNotMatch: true,
						pattern: {
							requiredPattern: '^[0-9]{9}$',
							actualValue: this.form.get('phoneRepeat')?.value,
						},
					},
				},
			});
			this.form.get(this.inputName)?.patchValue(this.form.get(this.inputName)?.value.slice(0, -1));
			this.keyboardService.setKeyboarForm(this.form.getRawValue());
			result[1] = this.keyboardType;
		}
		if (result[1] === 'space') result[1] = this.keyboardType;
		this.keyboardType = result[1];
		this.keyboard.setOptions({
			layoutName: result ? result[1] : this.keyboardType,
		});
	}

	onInputChange(event: any) {
		this.keyboard.setInput(event.target.value);
	}

	onInputChangeValue(input: string) {
		this.keyboardService.updateInput(input);
	}

	getValidators(field: string): ValidatorFn[] {
		if (field === 'email') {
			return [
				Validators.required,
				matchValuesValidator('repeat_email'),
				Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'),
			];
		} else if (field === 'repeat_email') {
			return [Validators.required, matchValuesValidator('email')];
		}

		if (field === 'phone') {
			return [Validators.required, matchValuesValidator('phoneRepeat'), Validators.pattern('^[0-9]{9}$')];
		}
		if (field === 'phoneRepeat') {
			return [Validators.required, matchValuesValidator('phone'), Validators.pattern('^[0-9]{9}$')];
		}
		return [Validators.required];
	}

	get feeCountFormControl(): FormControl {
		return this.form.get('feeCount') as FormControl;
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
