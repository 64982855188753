import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'truncatePhoneNumber',
})
export class TruncatePhoneNumberPipe implements PipeTransform {
	transform(value: number): string {
		if (!value) {
			return value.toString();
		}

		const parts = value
			.toString()
			.replace(/\D+/g, '')
			.replace(/(\d{3})(?=\d)/g, '$1 ');

		return parts.trim();
	}
}
