import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CardType } from 'src/app/core/enums/card-type.enum';
import { PaymentMethod } from 'src/app/core/enums/payment-method.model';
import { PurchaseType } from 'src/app/core/enums/purchase-type.enum';
import { ErrorService } from 'src/app/shared/services/error.service';
import { PurchaseService } from 'src/app/shared/services/purchase.service';
import { StepperService } from 'src/app/shared/services/stepper.service';
import { LanguageService } from '../../services/language.service';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
	step: number = 1;
	title: string = '';
	width = 0;
	initialBuyCardSteps: { [key: string]: number } = {
		'buy-card/1': 5,
		'buy-card/2': 10,
		'buy-card/details': 15,
		'buy-card/contract/step/1': 20,
		'buy-card/contract/step/2': 25,
		'buy-card/contract/step/3': 30,
		'buy-card/contract/step/4': 35,
		'buy-card/contract/step/6': 40,
		'buy-card/contract/step/7': 45,
		'buy-card/contract/step/8': 50,
		'buy-card/contract/step/9': 55,
		'pay/1': 60,
	};
	initialRechargeBalanceSteps: { [key: string]: number } = {
		'/recharge-balance/step/20': 15,
		'/recharge-balance/step/21': 35,
		'/recharge-balance/step/22': 50,
		'pay/1': 60,
		'pay/2': 65,
		'pay/8': 70,
	};
	payByCardPhysicalSIM: { [key: string]: number } = {
		'pay/2': 70,
		'pay/3': 80,
		'pay/4': 90,
		'pay/15': 95,
		'pay/5': 100,
		'pay/16': 100,
	};
	payByCashPhysicalSIM: { [key: string]: number } = {
		'pay/8': 75,
		'pay/9': 83,
		'pay/4': 90,
		'pay/15': 95,
		'pay/16': 100,
		'pay/5': 100,
	};
	payByCardeSIM: { [key: string]: number } = {
		'pay/2': 70,
		'pay/3': 75,
		'pay/11': 80,
	};
	payByCasheSIM: { [key: string]: number } = {
		'pay/8': 65,
		'pay/9': 70,
		'pay/11': 75,
	};
	eSIMSendEmail: { [key: string]: number } = {
		'pay/12': 80,
		'pay/14': 85,
		'pay/4': 90,
		'pay/15': 95,
		'pay/16': 100,
		'pay/5': 100,
	};
	eSIMSendprint: { [key: string]: number } = {
		'pay/13': 85,
		'pay/4': 95,
		'pay/15': 95,
		'pay/16': 100,
	};

	stepsPhysicCard: { [key: string]: number } = {
		'buy-card/1': 6.25,
		'buy-card/2': 12.5,
		'buy-card/details': 18.75,
		'buy-card/contract/step/1': 25,
		'buy-card/contract/step/2': 31.25,
		'buy-card/contract/step/3': 37.5,
		'buy-card/contract/step/4 ': 43.75,
		'buy-card/contract/step/6': 50,
		'buy-card/contract/step/7': 56.25,
		'buy-card/contract/step/8': 62.5,
		'buy-card/contract/step/9': 68.75,
		'pay/1': 75,
		'pay/2': 81.25,
		'pay/3': 87.5,
		'pay/4': 93.75,
		'pay/5': 100,
	};
	payStepsRechargeBalance: { [key: string]: number } = {
		'pay/2': 65,
		'pay/3': 70,
		'pay/4': 75,
		'pay/5': 80,
		'pay/15': 90,
		'pay/16': 100,
	};

	payStepsRechargeBalanceByCash: { [key: string]: number } = {
		'pay/8': 70,
		'pay/3': 75,
		'pay/4': 80,
		'pay/5': 85,
		'pay/15': 90,
		'pay/16': 100,
	};
	steps: { [key: string]: number } = {};
	urlTitle!: string;
	hideProgress: boolean = false;
	url: string = '';

	constructor(
		private readonly location: Location,
		private readonly router: Router,
		private readonly route: ActivatedRoute,
		readonly stepperService: StepperService,
		readonly purchaseService: PurchaseService,
		private readonly translateService: TranslateService,
		readonly errorService: ErrorService,
		private languageService: LanguageService,
	) {}

	ngOnInit(): void {
		this.languageService.selectedLanguage$.subscribe(() => {
			if (this.url.includes('/buy-card')) {
				this.translateService.get('GENERAL.BUY_CARD', {}).subscribe((text) => {
					this.urlTitle = text;
				});
			}
			if (this.url.includes('/recharge-balance')) {
				this.translateService.get('GENERAL.RECHARGE_BALANCE', {}).subscribe((text) => {
					this.urlTitle = text;
				});
			}
		});

		this.calculateProgress(this.location.path());
		this.steps = this.initialBuyCardSteps;
		this.route.queryParams.subscribe((value: any) => {
			this.step = value ? Number(value.step) : 1;
		});
		this.router.events.subscribe((event: any) => {
			if (event instanceof NavigationEnd) {
				this.url = event.url;
				if (this.url.includes('/start/2')) this.urlTitle = '';
				if (this.url === '/start/2' || this.url === '/help' || this.url === '/pay/6') {
					this.hideProgress = false;
				} else {
					this.hideProgress = true;
				}
				if (this.url.includes('/buy-card')) this.urlTitle = this.translateService.instant('GENERAL.BUY_CARD');
				if (this.url.includes('/recharge-balance'))
					this.urlTitle = this.translateService.instant('GENERAL.RECHARGE_BALANCE');

				if (this.url.includes('/buy-card/1')) this.steps = this.initialBuyCardSteps;
				if (this.url.includes('/recharge-balance/step/20')) this.steps = this.initialRechargeBalanceSteps;
				if (this.url.includes('/pay/1') && this.purchaseService.getPurchase().purchaseType === PurchaseType.RechargeBalance)
					this.steps =
						this.purchaseService.getPurchase().paymentMethod === PaymentMethod.CASH
							? Object.assign(this.steps, this.payStepsRechargeBalanceByCash)
							: Object.assign(this.steps, this.payStepsRechargeBalance);
				if (this.url.includes('/pay/2') && this.purchaseService.getPurchase().purchaseType === PurchaseType.BuyCard) {
					this.purchaseService.getPurchase().cardType === CardType.Physical
						? Object.assign(this.steps, this.payByCardPhysicalSIM)
						: Object.assign(this.steps, this.payByCardeSIM);
				}
				if (
					(this.url.includes('/pay/12') || this.url.includes('/pay/14')) &&
					this.purchaseService.getPurchase().purchaseType === PurchaseType.BuyCard
				) {
					Object.assign(this.steps, this.eSIMSendEmail);
				}
				if (this.url.includes('/pay/13') && this.purchaseService.getPurchase().purchaseType === PurchaseType.BuyCard) {
					Object.assign(this.steps, this.eSIMSendprint);
				}
				if (this.url.includes('/pay/8') && this.purchaseService.getPurchase().purchaseType === PurchaseType.BuyCard) {
					this.purchaseService.getPurchase().cardType === CardType.Physical
						? Object.assign(this.steps, this.payByCashPhysicalSIM)
						: Object.assign(this.steps, this.payByCasheSIM);
				}
				this.calculateProgress(event.url);
			}
		});
	}

	calculateProgress(url: string): void {
		Object.entries(this.steps).forEach(([key, value]) => {
			if (url.includes(key)) this.width = value;
		});
	}
}
