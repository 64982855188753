import { Component, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import {
	AbstractControl,
	FormBuilder,
	FormControl,
	FormGroup,
	ValidationErrors,
	ValidatorFn,
	Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { PurchaseType } from 'src/app/core/enums/purchase-type.enum';
import { KeyboardType } from 'src/app/shared/components/keyboard/keyboard.component';
import { AccessibilityService } from 'src/app/shared/services/accessibility.service';
import { ErrorService } from 'src/app/shared/services/error.service';
import { KeyboardService } from 'src/app/shared/services/keyboard.service';
import { PurchaseService } from 'src/app/shared/services/purchase.service';
import { StepperService } from 'src/app/shared/services/stepper.service';
import { RechargeBalanceService } from './services/recharge-balance.service';

@Component({
	selector: 'app-recharge-balance',
	templateUrl: './recharge-balance.component.html',
	styleUrls: ['./recharge-balance.component.scss'],
	host: {
		class: 'main',
	},
})
export class RechargeBalanceComponent implements OnInit, OnDestroy {
	changeOperator = false;
	operatorSelected = '';
	notProperty = false;
	editOperator = false;
	step = 1;
	cuantity = 5;
	imgSrcPath = '../../../assets/styles/resources/orange.png';
	form: FormGroup;
	operatorName = 'Orange';
	cuantities = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100];
	phone: string = '';
	phoneRepeat: string = '';
	phoneMatch: boolean = true;
	activeInput: string = 'phone';

	destroy$ = new Subject<void>();

	constructor(
		private rechargeBalanceService: RechargeBalanceService,
		private readonly fb: FormBuilder,
		private activeRoute: ActivatedRoute,
		private router: Router,
		public stepperService: StepperService,
		readonly purchaseService: PurchaseService,
		public keyboardService: KeyboardService,
		readonly errorService: ErrorService,
		readonly accessibilityService: AccessibilityService,
		private readonly renderer: Renderer2,
		private readonly el: ElementRef,
	) {
		this.form = this.fb.group({
			phone: ['', Validators.required],
			phoneRepeat: ['', Validators.required],
		});

		this.changeOperator = true;
		this.rechargeBalanceService.setActiveView(false);
	}

	ngOnInit(): void {
		this.cuantity = this.purchaseService.getPurchase().totalAmount!;
		if (this.cuantity === 0) this.cuantity = 5;
		if (this.purchaseService.getPurchase().totalAmount === 0) {
			this.purchaseService.setPurchase({ totalAmount: 5, remainingAmount: 5 });
		}
		this.keyboardService.setKeyboarData({
			isShow: true,
			theme: KeyboardType.NUMERIC,
			fieldFocused: 'phone',
			formFields: ['phone', 'phoneRepeat'],
		});

		this.activeRoute.params.pipe(takeUntil(this.destroy$)).subscribe({
			next: (step: any) => {
				this.step = parseInt(step.step) ?? 20;
				if (this.step !== 20 && this.keyboardService.getKeyboarData().isShow)
					this.keyboardService.setKeyboarData({ isShow: false });

				if (this.step !== 20 && this.accessibilityService.getAccessibility())
					this.renderer.removeClass(this.el.nativeElement, 'form-page--numbers');

				if (this.step === 20 && !this.changeOperator) {
					this.keyboardService.show();
					if (this.accessibilityService.getAccessibility())
						this.renderer.addClass(this.el.nativeElement, 'form-page--numbers');
				} else {
					this.keyboardService.hide();
				}

				if (this.step === 22) this.stepperService.setStep(22);
			},
			error: () => {},
		});

		this.errorService.hasError$.pipe(takeUntil(this.destroy$)).subscribe({
			next: (value) => {
				value
					? this.renderer.addClass(this.el.nativeElement, 'main--loader')
					: this.renderer.removeClass(this.el.nativeElement, 'main--loader');
			},
			error: () => this.renderer.removeClass(this.el.nativeElement, 'main--loader'),
		});

		this.purchaseService.setPurchase({ purchaseType: PurchaseType.RechargeBalance });

		this.keyboardService.keyboard$.pipe(takeUntil(this.destroy$)).subscribe({
			next: (value) => {
				this.form.patchValue(value.formValues);

				if (this.activeInput === 'phone') {
					this.phone = value.formValues.phone;
				} else if (this.activeInput === 'phoneRepeat') {
					this.phoneRepeat = value.formValues.phoneRepeat;
				}
				this.validatePhone();
			},
			error: () => {},
		});

		this.accessibilityService.accessibility$.pipe(takeUntil(this.destroy$)).subscribe({
			next: (value) => {
				if (this.step === 20) {
					value
						? this.renderer.addClass(this.el.nativeElement, 'form-page--numbers')
						: this.renderer.removeClass(this.el.nativeElement, 'form-page--numbers');
				}
			},
			error: () => this.renderer.removeClass(this.el.nativeElement, 'form-page--numbers'),
		});
	}

	validatePhone(): void {
		if (
			this.phone &&
			this.phoneRepeat &&
			(this.phone.length === this.phoneRepeat.length || this.phoneRepeat.length > 6)
		) {
			this.phoneMatch = this.phone === this.phoneRepeat;
		} else {
			this.phoneMatch = true;
		}
	}

	setActiveInput(inputName: string) {
		this.activeInput = inputName;
	}

	onFocus(fieldName: string) {
		this.keyboardService.setKeyboarData({
			isShow: true,
			theme: KeyboardType.NUMERIC,
			fieldFocused: fieldName,
			formFields: ['phone', 'phoneRepeat'],
		});

		this.keyboardService.show();
	}

	deleteField(field: string) {
		let functionField = `${field}FormControl`;
		let control = this[functionField as keyof this] as any;

		control.patchValue(null);
		this.keyboardService.setKeyboarData({ fieldFocused: field, resetValues: true, formValues: { [field]: '' } });
	}

	applyOperator(operator: string) {
		switch (operator) {
			case 'orange':
				this.purchaseService.setPurchase({ product_code: 'D300001' });
				break;
			case 'simyo':
				this.purchaseService.setPurchase({ product_code: 'SIMYO00' });
				break;
			case 'digi':
				this.purchaseService.setPurchase({ product_code: 'DIGI001' });
				break;
			case 'vodafone':
				this.purchaseService.setPurchase({ product_code: 'D200001' });
				break;
			case 'masmovil':
				this.purchaseService.setPurchase({ product_code: 'MASM001' });
				break;
			case 'yoigo':
				this.purchaseService.setPurchase({ product_code: 'D500001' });
				break;
			case 'movistar':
				this.purchaseService.setPurchase({ product_code: 'D100001' });
				break;
			case 'llamaya':
				this.purchaseService.setPurchase({ product_code: 'LLAM001' });
				break;
			case 'lyca':
				this.purchaseService.setPurchase({ product_code: 'LYCA001' });
				break;
			case 'lebara':
				this.purchaseService.setPurchase({ product_code: 'D600001' });
				break;
			case 'bestmovil':
				this.purchaseService.setPurchase({ product_code: 'BESTMOV' });
				break;
			case 'blau':
				this.purchaseService.setPurchase({ product_code: 'BLAU000' });
				break;
			case 'eplus':
				this.purchaseService.setPurchase({ product_code: 'EPLUS00' });
				break;
			case 'hablapp':
				this.purchaseService.setPurchase({ product_code: 'RTR0667' });
				break;
			case 'happymovil':
				this.purchaseService.setPurchase({ product_code: 'HAPPYMO' });
				break;
			case 'suop':
				this.purchaseService.setPurchase({ product_code: 'RTR1016' });
				break;
			case 'youmobile':
				this.purchaseService.setPurchase({ product_code: 'YOUMBLE' });
				break;
			default:
				this.purchaseService.setPurchase({ product_code: 'D300001' });
				break;
		}
		this.operatorName = operator;
		this.operatorSelected = operator ?? '';
		this.imgSrcPath =
			operator === 'orange'
				? '../../../assets/styles/resources/orange.png'
				: '../../../assets/styles/resources/' + operator + '.svg';
		this.step += 1;
		this.changeOperator = false;
		this.rechargeBalanceService.setActiveView(true);
		this.keyboardService.show();
	}

	setCuantity(value: number) {
		this.cuantity = value;
		this.purchaseService.setPurchase({ totalAmount: this.cuantity, remainingAmount: this.cuantity });
	}

	nextPage() {
		this.step += 1;
		this.router.navigate(['/recharge-balance/step', this.step]);
	}

	goToInitialStep() {
		this.changeOperator = true;
		this.rechargeBalanceService.setActiveView(false);
		this.keyboardService.hide();
		this.router.navigate(['/recharge-balance/step', 20]);
	}

	goToPay() {
		this.step++;
		this.router.navigate(['/pay']);
	}

	changeToOperatorPage() {
		this.keyboardService.hide();
		this.changeOperator = true;
		this.rechargeBalanceService.setActiveView(false);
	}

	get phoneFormControl(): FormControl {
		return this.form.get('phone') as FormControl;
	}

	get phoneRepeatFormControl(): FormControl {
		return this.form.get('phoneRepeat') as FormControl;
	}

	mobileNumberValidator(param: string): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			const valid = /^\d{9}$/.test(control.value);
			if (param === 'phone') return valid ? null : { invalidPhone: true };
			if (param === 'phoneRepeat') return valid ? null : { invalidPhoneRepeat: true };
			return null;
		};
	}

	ngOnDestroy(): void {
		this.keyboardService.resetKeyboard(['phone', 'phoneRepeat']);
		this.destroy$.next();
		this.destroy$.complete();
	}
}
