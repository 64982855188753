import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StartComponent } from './core/components/start/start.component';
import { jwtGuard } from './core/guards/jwt.guard';
import { HelpComponent } from './shared/components/help/help.component';

const routes: Routes = [
	{
		path: 'start/:step',
		component: StartComponent,
	},
	{
		path: 'start/1',
		component: StartComponent,
	},
	{
		path: 'recharge-balance/step/:step',
		loadChildren: () =>
			import('../app/features/recharge-balance/recharge-balance.module').then((m) => m.RechargeBalanceModule),
	},
	{
		path: 'recharge-balance',
		redirectTo: 'recharge-balance/step/20',
	},
	{
		path: 'buy-card',
		loadChildren: () => import('../app/features/buy-card/buy-card.module').then((m) => m.BuyCardModule),
	},
	{
		path: 'pay/:step',
		loadChildren: () => import('../app/features/pay/pay.module').then((m) => m.PayModule),
	},
	{
		path: 'pay',
		redirectTo: 'pay/1',
	},
	{
		path: '',
		redirectTo: 'start/1',
		pathMatch: 'full',
	},
	{
		path: 'jwt/:jwt',
		component: StartComponent,
		canActivate: [jwtGuard],
	},
	{
		path: 'help',
		component: HelpComponent,
	},
	{ path: '**', redirectTo: 'start/1' },
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
