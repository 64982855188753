import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

@Component({
	selector: 'app-item',
	templateUrl: './item.component.html',
	styleUrls: ['./item.component.scss'],
})
export class ItemComponent implements OnInit, OnDestroy {
	@Input()
	tagLabel: string = '';

	@Input()
	title: string = '';

	@Input()
	alert: string = '';

	@Input()
	price: string = '';

	@Input()
	tagColor: string = '';

	@Input()
	type: 'amount' | 'info' = 'info';

	@Input()
	pricePeriod: string = '';

	@Output()
	valueChange = new EventEmitter<number>();

	form: FormGroup;

	private destroy$ = new Subject<void>();

	constructor(private readonly fb: FormBuilder) {
		this.form = this.fb.group({
			amount: [1],
		});
	}

	ngOnInit(): void {
		this.amountFormControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((c) => this.valueChange.emit(c));
	}

	addValue() {
		this.amountFormControl.patchValue(Number(this.amountFormControl.value));
	}

	subtractValue() {
		if (this.amountFormControl.value > 1) this.amountFormControl.patchValue(Number(this.amountFormControl.value) - 1);
	}

	get amountFormControl(): FormControl {
		return this.form.get('amount') as FormControl;
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
