import { Location } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class jwtGuard {
	constructor(
		public router: Router,
		private readonly cookieService: CookieService,
		private readonly location: Location,
	) {}

	canActivate(): boolean {
		const url = this.location.path();
		if (url.includes('/jwt/')) {
			const chunk = url.split('/jwt/');
			const token = chunk[1];
			this.cookieService.set('token', token, 1, '/', undefined, true, 'Strict');
		}
		this.router.navigate(['/']);
		return true;
	}
}

export const isLogged: CanActivateFn = () => {
	return inject(jwtGuard).canActivate();
};
