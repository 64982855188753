<div class="main__header">
	<h1>{{ 'START.HELP' | translate }}</h1>
</div>
<div class="collapse-group">
	<ng-container *ngFor="let item of help; let i = index">
		<div
			class="collapse collapse--lg"
			[ngClass]="{ 'collapse--expanded': item.visible }"
			(click)="item.visible === true ? (item.visible = false) : changeObjectStatus(i)"
		>
			<div class="collapse__header">
				<div class="collapse__header__title">
					<div class="title__text">
						<h4>{{ 'HELP.' + item.title + '.TITLE' | translate }}</h4>
					</div>
				</div>
				<i class="icon-triangle-down"></i>
			</div>
			<ng-container *ngIf="objectValues(item)[0] === 'VALID_DOCUMENTS'; else verifyDocumentNotValid">
				<div class="collapse__body">
					<ul>
						<li>{{ 'HELP.' + objectValues(item)[0] + '.OPTIONS.PASSPORT' | translate }}</li>
						<li>{{ 'HELP.' + objectValues(item)[0] + '.OPTIONS.IDENTITY_DOCUMENT' | translate }}</li>
						<li>{{ 'HELP.' + objectValues(item)[0] + '.OPTIONS.DNI' | translate }}</li>
						<li>{{ 'HELP.' + objectValues(item)[0] + '.OPTIONS.RESIDENCE_CARD' | translate }}</li>
					</ul>
				</div>
			</ng-container>
			<ng-template #verifyDocumentNotValid>
				<ng-container *ngIf="objectValues(item)[0] === 'DOCUMENT_NOT_VALID'; else verifyAvailableCompanies">
					<div class="collapse__body">
						<ul>
							<li>{{ 'HELP.' + objectValues(item)[0] + '.OPTIONS.VALID_DOCUMENT' | translate }}</li>
							<li>{{ 'HELP.' + objectValues(item)[0] + '.OPTIONS.ACCEPT_DOCUMENT' | translate }}</li>
							<li>{{ 'HELP.' + objectValues(item)[0] + '.OPTIONS.PLUS_18' | translate }}</li>
						</ul>
					</div>
				</ng-container>
			</ng-template>
			<ng-template #verifyAvailableCompanies>
				<ng-container *ngIf="objectValues(item)[0] === 'AVAILABLE_COMPANIES'; else showGeneral">
					<div class="collapse__body">
						<ul>
							<li>{{ 'HELP.' + objectValues(item)[0] + '.OPTIONS.ORANGE' | translate }}</li>
						</ul>
					</div>
				</ng-container>
			</ng-template>
			<ng-template #showGeneral>
				<ng-container>
					<div class="collapse__body" [innerHTML]="'HELP.' + objectValues(item)[0] + '.CONTENT' | translate"></div>
				</ng-container>
			</ng-template>
		</div>
	</ng-container>
</div>
