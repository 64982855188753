<ng-container *ngIf="showStartFooter">
	<button class="button button--sm" (click)="openHelpPage()">
		<i class="icon-help"></i>
		{{ 'START.HELP' | translate }}
	</button>
	<app-button
		icon="icon-accessibility"
		size="sm"
		label=" {{ 'START.ACCESSIBILITY' | translate }}"
		(clickEvent)="accessibility()"
	>
	</app-button>
</ng-container>

<ng-container *ngIf="!showStartFooter">
	<app-button
		[disabled]="
			(purchaseService.purchase$ | async)?.totalAmount !== (purchaseService.purchase$ | async)?.remainingAmount ||
			((purchaseService.purchase$ | async)?.isPaymentSuccessful &&
				(this.location.path().includes('recharge-balance/step/22') ||
					this.location.path().includes('buy-card/contract/step/9') ||
					this.location.path().includes('pay/6')))
		"
		icon="icon-chevron-left"
		type="filled"
		size="sm"
		label=" {{ 'GENERAL.GET_BACK' | translate }}"
		(clickEvent)="goBack()"
	>
	</app-button>
	<div class="button-group">
		<app-button
			icon="icon-log-out"
			size="sm"
			label=" {{ 'GENERAL.GO_OUT' | translate }}"
			(clickEvent)="openExitDialog()"
			[disabled]="!!(purchaseService.purchase$ | async)?.disabledExit"
		>
		</app-button>
		<app-button
			icon="icon-language-2"
			size="sm"
			label="{{ 'GENERAL.LANGUAGE' | translate }}/language"
			class="button-language"
			(clickEvent)="openDialog()"
		>
		</app-button>
		<app-button
			icon="icon-accessibility"
			size="sm"
			label="{{ accessibilityService.getAccessibility() ? ('GENERAL.DEACTIVATE' | translate) : '' }}"
			(clickEvent)="accessibility()"
		>
		</app-button>
		<app-button icon="icon-help" size="sm" (clickEvent)="openHelpPage()"> </app-button>
	</div>
</ng-container>
