import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OKResponse } from 'src/app/core/models/ok-response.model';
import { environment } from 'src/environments/environment';

@Injectable()
export class ValidationService {
	protected http: HttpClient;

	constructor(http: HttpClient) {
		this.http = http;
	}

	getValidation(): Observable<OKResponse> {
		return this.http.get<OKResponse>(environment.apiUrl + `/scanner/scan-document`);
	}
}
