import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class StepperService {
	private stepSubject = new BehaviorSubject<number>(1);
	private isOnPurchaseResume = new BehaviorSubject<boolean>(false);
	step$: Observable<number> = this.stepSubject.asObservable();

	setStep(step: number) {
		if (!isNaN(step) && this.getStep() !== step) this.stepSubject.next(step);
	}

	getStep(): number {
		return this.stepSubject.value;
	}

	setIsOnPurchaseResume(step: boolean) {
		this.isOnPurchaseResume.next(step);
	}

	getIsOnPurchaseResume(): boolean {
		return this.isOnPurchaseResume.value;
	}
}
