import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { OKResponse } from 'src/app/core/models/ok-response.model';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class BannerService {
	protected http: HttpClient;
	private bannersSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
	banners$: Observable<any[]> = this.bannersSubject.asObservable();

	constructor(http: HttpClient) {
		this.http = http;
	}

	getBanners(): Observable<OKResponse> {
		return this.http.post<OKResponse>(environment.apiUrl + `/communications/banner/list`, {});
	}

	setBanners(banners: any[]) {
		this.bannersSubject.next(banners);
	}
}
