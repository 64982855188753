import { Component, ElementRef, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject, interval, takeUntil, takeWhile } from 'rxjs';
import { AccessibilityService } from '../../services/accessibility.service';

@Component({
	selector: 'app-inactivity-dialog',
	templateUrl: './inactivity-dialog.component.html',
	styleUrls: ['./inactivity-dialog.component.scss'],
})
export class InactivityDialogComponent implements OnInit, OnDestroy {
	private destroy$ = new Subject<void>();
	countdown = 30;

	constructor(
		@Inject(MAT_DIALOG_DATA) private data: any,
		private readonly accessibilityService: AccessibilityService,
		public dialogRef: MatDialogRef<InactivityDialogComponent>,
		private el: ElementRef,
		private renderer: Renderer2,
	) {
		this.countdown = data.countdown;
	}

	ngOnInit(): void {
		if (this.accessibilityService.getAccessibility())
			this.renderer.addClass(this.el.nativeElement, 'modal-accessibility');
		interval(1000)
			.pipe(
				takeWhile(() => this.countdown > 0),
				takeUntil(this.destroy$),
			)
			.subscribe(() => {
				this.countdown--;
				if (this.countdown === 0) this.dialogRef.close('end');
			});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
