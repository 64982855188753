<ng-container *ngIf="step === 2">
	<div class="header__title">
		<div class="header__title__logo">
			<img src="../../../assets/styles/resources/orange.png" alt="" />
		</div>
	</div>
</ng-container>
<ng-container *ngIf="step !== 2">
	<div class="header__title">
		<div class="header__title__logo">
			<img src="../../../assets/styles/resources/orange.png" alt="" />
		</div>
		<h3>
			{{ urlTitle }}
		</h3>
	</div>
	<div
		[ngClass]="{
			'progress--sucess': width === 100,
			'progress--error': (errorService.hasError$ | async),
		}"
		class="progress progress"
		*ngIf="hideProgress"
	>
		<div class="progress__bar">
			<div class="progress__bar__color" [ngStyle]="{ width: width + '%' }"></div>
		</div>
	</div>
</ng-container>
