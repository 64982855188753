import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { OKResponse } from 'src/app/core/models/ok-response.model';
import { environment } from 'src/environments/environment';
import { PurchaseService } from './purchase.service';

@Injectable()
export class TicketService {
	protected http: HttpClient;

	constructor(
		http: HttpClient,
		private readonly purchaseService: PurchaseService,
	) {
		this.http = http;
	}

	printTicketOrder(order_id: string): Observable<OKResponse> {
		return this.http.post<OKResponse>(environment.apiUrl + `/printer/print-order-ticket`, {
			order_id,
		});
	}

	printTicketRecharge(recharge_id: string): Observable<OKResponse> {
		return this.http.post<OKResponse>(environment.apiUrl + `/printer/print-recharge-ticket`, {
			recharge_id,
		});
	}

	printRefundTicket(order_id: string, type: string, full_refund?: string): Observable<OKResponse> {
		if (!full_refund)
			return this.http.post<OKResponse>(environment.apiUrl + `/printer/print-cashback`, {
				uuid: order_id,
				type,
			});

		return this.http.post<OKResponse>(environment.apiUrl + `/printer/print-cashback`, {
			uuid: order_id,
			type,
			full_refund,
		});
	}

	printESIMTicket(): Observable<any> {
		const purchase = this.purchaseService.getPurchase();
		return this.http
			.post<any>(environment.apiUrl + `/printer/print-esim-ticket`, {
				order_id: purchase.orderId,
			})
			.pipe(catchError(this.handleError));
	}

	private handleError(error: HttpErrorResponse) {
		return throwError(error);
	}
}
