<div class="item">
	<div class="item__tarifa" [ngClass]="tagColor">
		<h5>{{ tagLabel }}</h5>
	</div>
	<div class="item__body">
		<div class="item__body__title">
			<div class="title__content">
				<div class="title__content__info">
					<h3>{{ title }}</h3>
					<div class="price">
						<h2>{{ price }}</h2>
						<h5>€/{{ pricePeriod }}{{ 'GENERAL.PERIOD_PER_DAY' | translate }}</h5>
					</div>
				</div>
				<div class="title__content__quantity">
					<h4>1ud</h4>
				</div>
			</div>
		</div>
		<ng-container *ngIf="type === 'info'">
			<div class="divider"></div>
			<div class="item__body__details">
				<div class="details__info">
					<div class="info-group">
						<i class="icon-gb icon--xs"></i>
						<div class="info-group__text">
							<label for=""> </label>
							<span></span>
						</div>
					</div>
					<div class="info-group">
						<i class="icon-mobile icon--xs"></i>
						<div class="info-group__text">
							<label for=""> 100min. </label>
							<span></span>
						</div>
					</div>
				</div>
				<div class="details__buttons">
					<button class="button button--important button--sm" type="button" routerLink="/card-details">
						{{ 'GENERAL.WANT_IT' | translate }}
					</button>
					<a href="comprar-sim-fisica-modal.html">{{ 'GENERAL.SEE_DETAILS' | translate }}</a>
				</div>
			</div>
		</ng-container>
	</div>
</div>
