import { Component, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { AccessibilityService } from 'src/app/shared/services/accessibility.service';
import { BuyCardService } from '../../services/buy-card.service';

@Component({
	selector: 'app-romaing-modal',
	templateUrl: './romaing-modal.component.html',
	styleUrls: ['./romaing-modal.component.scss'],
})
export class RomaingModalComponent implements OnInit, OnDestroy {
	dialogRef: MatDialogRef<RomaingModalComponent>;
	detailIdSelected!: number;
	details!: any[];
	isLoading = true;
	roaming!: any;
	indexSelected = 0;

	destroy$ = new Subject<void>();

	constructor(
		dialogRef: MatDialogRef<RomaingModalComponent>,
		readonly buyCardService: BuyCardService,
		private el: ElementRef,
		private renderer: Renderer2,
		readonly accessibilityService: AccessibilityService,
	) {
		this.dialogRef = dialogRef;
	}

	ngOnInit(): void {
		this.buyCardService.fees$.pipe(takeUntil(this.destroy$)).subscribe({
			next: (fee) => {
				if (fee.roamingData) {
					this.roaming = fee.roamingData;
					this.isLoading = false;
				}
			},
			error: () => {},
		});

		if (this.accessibilityService.getAccessibility())
			this.renderer.addClass(this.el.nativeElement, 'modal-accessibility');
	}

	subtract() {
		if (this.indexSelected === 0) {
			this.indexSelected = this.roaming.details.length - 1;
		} else {
			this.indexSelected > 0 ? this.indexSelected-- : 1;
		}
	}

	add() {
		if (this.indexSelected === this.roaming.details.length - 1) {
			this.indexSelected = 0;
		} else {
			this.indexSelected < this.roaming.details.length - 1 ? this.indexSelected++ : this.roaming.details.length - 1;
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
