import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { TruncatePhoneNumberPipe } from 'src/app/core/pipes/truncate-phone-number.pipe';
import { SharedModule } from 'src/app/shared/shared.module';
import { RechargeBalanceRoutingModule } from './recharge-balance-routing.module';
import { RechargeBalanceComponent } from './recharge-balance.component';

@NgModule({
	declarations: [RechargeBalanceComponent, TruncatePhoneNumberPipe],
	imports: [
		CommonModule,
		RouterModule,
		SharedModule,
		TranslateModule,
		MatDialogModule,
		RechargeBalanceRoutingModule,
		ReactiveFormsModule,
	],
	providers: [],
	bootstrap: [],
})
export class RechargeBalanceModule {}
