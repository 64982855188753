<div class="modal show">
	<div class="modal__content">
		<div class="modal__content__body">
			<div class="body__header">
				<h2>{{ 'EXIT.ABANDON' | translate }}</h2>
				<span>{{ 'EXIT.LOSE_DATA' | translate }}</span>
			</div>
		</div>
		<div class="modal__content__buttons">
			<button class="button button--negative" type="button" (click)="dialogRef.close('confirm')">
				{{ 'GENERAL.CONFIRM' | translate }}
			</button>
			<button class="button" type="button" (click)="dialogRef.close()">{{ 'GENERAL.CANCEL' | translate }}</button>
		</div>
	</div>
</div>
