import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LanguageService } from 'src/app/core/services/language.service';
import { PurchaseService } from 'src/app/shared/services/purchase.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class RechargeBalanceService {
	protected http: HttpClient;

	operator: BehaviorSubject<string> = new BehaviorSubject<string>('');
	activeView$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	constructor(
		http: HttpClient,
		private readonly purchaseService: PurchaseService,
		private readonly languageService: LanguageService,
	) {
		this.http = http;
	}

	setActiveView(hasCorrectView: boolean) {
		this.activeView$.next(hasCorrectView);
	}
	getActiveView(): Observable<boolean> {
		return this.activeView$.asObservable();
	}

	createOrder() {
		const order = this.purchaseService.getPurchase();
		const lang = this.languageService.getLanguage();
		return this.http.post<any>(environment.apiUrl + `/recharge/create`, {
			order_subtotal: order.totalAmount,
			order_total: order.totalAmount,
			email_copy: order.emailCopy,
			msisdn: order.msisdn,
			payment_method: order.paymentMethod,
			product_code: order.product_code ?? 'D300001',
			lang,
		});
	}
}
