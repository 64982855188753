import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BuyCardComponent } from './buy-card.component';
import { CardDetailsComponent } from './components/card-details/card-details.component';
import { ContractComponent } from './components/contract/contract.component';

const routes: Routes = [
	{ path: '', component: BuyCardComponent },
	{ path: 'details/:productId', component: CardDetailsComponent },
	{ path: ':step', component: BuyCardComponent },
	{ path: 'contract', component: ContractComponent },
	{ path: 'contract/step/:step', component: ContractComponent },
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class BuyCardRoutingModule {}
