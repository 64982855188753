import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject, takeUntil } from 'rxjs';
import { CardType } from 'src/app/core/enums/card-type.enum';
import { Fee } from 'src/app/core/models/fee.model';
import { Romaing } from 'src/app/core/models/roaming.model';
import { ErrorService } from 'src/app/shared/services/error.service';
import { PurchaseService } from 'src/app/shared/services/purchase.service';
import { BuyCardService } from '../../services/buy-card.service';
import { RomaingModalComponent } from '../romaing-modal/romaing-modal.component';

@Component({
	selector: 'app-card-details',
	templateUrl: './card-details.component.html',
	styleUrls: ['./card-details.component.scss'],
	host: {
		class: 'main',
	},
})
export class CardDetailsComponent implements OnInit, OnDestroy {
	feeAmount = 1;
	feeCount = 1;
	isRoamingActions = false;
	totalAmount = 0;
	totalRoamingAmount = 0;
	amount = 0;
	CardType = CardType;
	productId!: string;
	fee$!: Observable<any>;
	tagColor!: string;
	bonusId!: string;
	roamingData!: Romaing;
	fee!: Fee;
	isLoading = false;

	destroy$ = new Subject<void>();

	constructor(
		private readonly router: Router,
		readonly route: ActivatedRoute,
		private readonly dialog: MatDialog,
		readonly purchaseService: PurchaseService,
		readonly buyCardService: BuyCardService,
		readonly errorService: ErrorService,
	) {}

	ngOnInit(): void {
		this.productId = this.route.snapshot.paramMap.get('productId') || '';

		this.purchaseService.setPurchase({ productId: this.productId });

		this.isLoading = true;

		//ACTIVATE ONCE ROAMING IS ACTIVE
		// this.buyCardService.getRoaming().subscribe((romaingData) => {
		// 	this.bonusId = romaingData.info.data[0].bonus_id;
		// 	this.buyCardService.getRoamingDetails(romaingData.info.data[0].bonus_id).subscribe((romainData) => {
		// 		this.roamingData = romainData;
		// 		this.isLoading = false;
		// 		this.buyCardService.setRoamingData(romainData);
		// 	});
		// });
		this.buyCardService.fees$.pipe(takeUntil(this.destroy$)).subscribe({
			next: (data) => {
				if (data.feeSelected) {
					this.fee = data.feeSelected;
					this.amount = Number(data.feeSelected.price);
					this.totalAmount = this.amount;
					this.purchaseService.setPurchase({
						feeSubtitle: data.feeSelected.subtitle,
						feePrice: Number(data.feeSelected.price),
						totalAmount: this.totalAmount,
						remainingAmount: this.totalAmount,
						feeAmount: this.amount,
						feeCount: 1,
					});
				}
			},
			error: () => {},
		});

		this.route.queryParamMap.pipe(takeUntil(this.destroy$)).subscribe({
			next: (value) => (this.tagColor = value.get('tagColor') ?? ''),
			error: () => (this.tagColor = ''),
		});
	}

	valueChange($event: number) {
		this.feeCount = $event;
		this.feeAmount = this.amount * $event;
		this.totalRoamingAmount = this.isRoamingActions ? Number(this.roamingData?.info?.price) * $event : 0;
		this.totalAmount = this.feeAmount + this.totalRoamingAmount;
		this.purchaseService.setPurchase({
			feeAmount: this.feeAmount,
			feeCount: this.feeCount,
			totalAmount: this.totalAmount,
			remainingAmount: this.totalAmount,
		});
	}

	openDialog() {
		this.dialog.open(RomaingModalComponent);
	}

	addRoaming() {
		this.totalRoamingAmount = Number(this.roamingData?.info?.price) * this.feeCount;
		this.isRoamingActions = !this.isRoamingActions;
		this.totalAmount += this.totalRoamingAmount;
		this.purchaseService.setPurchase({
			feeAmount: this.feeAmount,
			totalAmount: this.totalAmount,
			remainingAmount: this.totalAmount,
			isRomaingActivated: true,
			bonusId: this.bonusId,
		});
	}

	subtractRoaming() {
		this.isRoamingActions = !this.isRoamingActions;
		this.totalAmount -= this.totalRoamingAmount;
		this.purchaseService.setPurchase({ totalAmount: this.totalAmount, remainingAmount: this.totalAmount });
	}

	goToContract() {
		this.router.navigate(['buy-card/contract']);
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
