import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RechargeBalanceComponent } from './recharge-balance.component';

const routes: Routes = [
	{ path: '', component: RechargeBalanceComponent }
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class RechargeBalanceRoutingModule {}
