import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
	constructor(private readonly coockies: CookieService) {}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		const authRequest = request.clone({
			setHeaders: {
				Authorization: `Bearer ${this.coockies.get('token')}`,
			},
		});

		return next.handle(authRequest);
	}
}
